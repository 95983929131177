.register_option_container {
  position: relative;
  background-color: white !important;
  width: 100%;
  height: 62.61vh !important;
  @media only screen and (min-width: 414px) and (max-width: 1023px) {
    height: 500px !important;
  }
  display: flex;
  align-items: center;
  margin: auto !important;
  margin-top: 55px !important;
}

.layout_register_option {
  position: relative;
  height: 40.61vh !important;

  background-color: white !important;
}

.layout_title {
  color: #232735;
  font-size: 17px;
  font-weight: bold;
  font-family: 'DINPro', sans-serif;
}

.layout_subtitle {
  color: #4D4F5C;
  font-size: 14px;
  font-family: 'DINPro', sans-serif;
}

.register_select_container {
  padding-top: 6.28vh;
}

.select_option_container {
  display: flex !important;
  align-items: center;
}

.vertical_line {
  height: 61px;
  width: 0px;
  border-radius: 5px;
  border: #707070 solid 2px;
}

.next_button {
  width: 9.66vw;
  height: 4.85vh;
  @media only screen and (min-width: 414px) and (max-width: 1023px) {
    width: 200px !important;
  }
  background-color: #029AFF;
  border: none;
  border-radius: 5px;
  margin-top: 2.73vh;
  color: white;
  font-weight: bold;
  font-family: 'DINPro', sans-serif; 
  -webkit-box-shadow: 0 0 5px 2px #D3D3D3 !important;
          box-shadow: 0 0 5px 2px #D3D3D3 !important;
}

.radio_group_row {
  width: 27vw;
  display: flex !important;
  justify-content: space-between;
}

