#service-filter-form {
  width: 100%;
  margin-top: 50px;
  .ant-row {
    #green-select-border-radius-wrapper {
      .ant-form-item-control {
        .ant-form-item-control-input {
          height: 62px;
          background-color: #00A550;
          border-top-right-radius: 15px;
          border-bottom-left-radius: 15px;
          .ant-form-item-control-input-content {
            .ant-select {
              .ant-select-selector {
                background-color: #00A550 !important;
                color: #FFFFFF;
                font-weight: bold;
                font-size: 16px;
                border: none;
              }
            }
          }
          .ant-select-arrow {
            color: #FFFFFF;
          }
        }
      }
    }
    .ant-col {
      .ant-form-item {
        .ant-form-item-label {
          label {
            color: #FFFFFF;
            font-weight: bold;
          }
        }
      }
      #btn-search {
        background-color: #CCD874;
        color: #012509;
        font-weight: bold;
      }
      #reset-filter-btn {
        border: none;
        border-radius: 5px;
        background-color: #00A550;
        color: white;
        font-weight: bold;
      }
      #btn-post-product {
        background-color: rgb(10, 170, 233);
        border: none;
        color: #FFFFFF;
        font-weight: bold;
        padding: 0px;
        width: 100%;
      }
    }
  }
}
@primary-color: #1DA57A;