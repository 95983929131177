.contact_body_container {
  background-color: white;
  margin-top: 48px;
  margin-bottom: 48px;
  margin-left: 57px;
  margin-right: 57px;
}

.divider {
  border: solid 1px #066739;
}