@desktop:   ~"(min-width: 960px)";
@mobile:    ~"(min-width: 320px) and (max-width: 768px)";

@media @desktop {
  #checkout-container-wrapper {
    padding: 12px 24px;
    height: auto;
    #checkout-wrapper {
      padding-top: 24px;
      width: 100%;
      max-width: 1260px;
      height: 100%;
      background-color: #FFFFFF;
      #steps-wrapper {
        height: 40px;
        margin-bottom: 16px;
        .ant-steps {
          height: 100%;
          width: 600px !important;
          margin: auto;
        }
      }
      #table-overview-cart-wrapper {
        .custom-table {
          .ant-table-thead {
            .ant-table-cell {
              background-color: #CCD874 !important;
              color: #012509;
              font-weight: bold;
            }
          }
          .ant-table-row {
            .ant-table-cell {
              color: #012509;
              font-weight: bold;
            }
          }
        }
      }
      #address_form_title {
        font-size: 24px;
        font-weight: bold;
        color: #34533E;
      }
    }
  }
}

@media @mobile {
  #checkout-container-wrapper {
    padding: 12px 12px;
    height: auto;
    #checkout-wrapper {
      margin-left: 0px !important; 
      margin-right: 0px !important;
      padding-top: 24px;
      width: 100%;
      max-width: 1260px;
      background-color: #FFFFFF;
      #table-overview-cart-wrapper {
        .custom-table {
          .ant-table-thead {
            .ant-table-cell {
              background-color: #CCD874 !important;
              color: #012509;
              font-weight: bold;
            }
          }
          .ant-table-row {
            .ant-table-cell {
              color: #012509;
              font-weight: bold;
            }
          }
        }
      }
      #address_form_title {
        font-size: 15px;
        font-weight: bold;
        color: #34533E;
      }
    }
  }
}
@primary-color: #1DA57A;