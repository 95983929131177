.col_info {
  margin-top: 10px;
  text-align: left;
  p {
    margin-bottom: 8px;
  }
  h2 {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  h3 {
    font-size: 13px;
    font-weight: bold;
  }
  p {
    font-size: 11px;
  }
  ul {
    padding-left: 5px;
    list-style-type: none;
  }
}

.col_footer {
  ._title {
    font-size: 18px;
  }
  li {
    a {
      font-size: 11px;
    }
  }
}