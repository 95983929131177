@desktop:   ~"(min-width: 960px)";
@mobile:    ~"(min-width: 320px) and (max-width: 768px)";

@media @desktop {
  .layout_section {
    background-color: white;
  }
  
  .title {
    color: #012509 !important;
    font-size: 14px !important;
    font-weight: bold;
    font-family: 'DINPro', sans-serif !important;
    margin-bottom: 24px;
    margin-top: 24px;
  }
  
  .content_form_container {
    background-color: white;
  }
  
  .row_container_give_price {
    max-width: 826px;
    width: 100%;
    height: 60px;
    border: #00A550 solid 0.5px;
    -webkit-box-shadow: 0 0 5px 2px #D3D3D3 !important;
            box-shadow: 0 0 5px 2px #D3D3D3 !important;
    align-items: center;
    .ant-form-item {
      margin-bottom: 0px;
    }
  }
  
  .row_container_give_price_label {
    color: #A1A0AE;
    font-family: 'DINPro', sans-serif !important;
  }
  
  .price_input {
    width: 100%;
    height: 30px;
    border: #012509 solid 1px;
    border-radius: 4px;
  }
  
  .price_unit_selection {
    width: 100%;
    height: 30px;
    border: #012509 solid 1px;
    border-radius: 4px;
  }

  #fee-title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;
    font-weight: bold;
    p {
      font-size: 20px;
    }
  }
  
  .information_project_input_area {
    width: 56.66vw !important;
    height: 20.08vh !important;
    border: #00A550 solid 0.5px;
    -webkit-box-shadow: 0 0 5px 2px #D3D3D3 !important;
            box-shadow: 0 0 5px 2px #D3D3D3 !important;
  }
  
  .information_project_tips {
    color: #A1A0AE;
    font-family: 'DINPro', sans-serif !important;
  }
  
  .text_cs {
    font-size: 11px !important;
    font-family: 'DINPro', sans-serif !important;
    color: #A1A0AE;
    display: flex;
    flex-direction: row-reverse;
  }
  
  .comfirm_button {
    background-color: #00A550;
    width: 90%;
    height: 40px;
    border-radius: 6px;
    font-size: 17px !important;
    font-family: 'DINPro', sans-serif !important;
    font-weight: bold;
  }
  
  .cancel_button {
    background-color: #FF2323;
    width: 90%;
    height: 40px;
    border-radius: 6px;
    font-size: 17px !important;
    font-family: 'DINPro', sans-serif !important;
    font-weight: bold;
  }
  
  .fee_container {
    margin-top: 30px;
    width: 378px;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 40px;
  }
  
  .fee_heading {
    background-color: #FFF000;
    width: 262px;
    height: 26px;
    margin: auto;
  }
  
  .circle_green_coin {
    height: 40px;
    color: #00A550;
    margin: auto;
    font-size: 50px;
    font-weight: bold;
    font-family: 'DINPro', sans-serif;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  
  .fee_footer_notify {
    display: flex;
    flex-direction: row;
  }
  
  .fee_footer_notify img {
    width: 27px;
    height: 27px;
  }
  
  .btn_add_coin_fee {
    width: 168px;
    height: 40px;
    border-radius: 6px;
    background-color: #029AFF;
    color: #FFFFFF;
    font-weight: bold;
    font-family: 'DINPro', sans-serif;
    border: none;
    margin: auto;
  }
};

@media @mobile {
  .layout_section {
    background-color: white;
  }
  
  .title {
    color: #012509 !important;
    font-size: 14px !important;
    font-weight: bold;
    font-family: 'DINPro', sans-serif !important;
  }
  
  .content_form_container {
    background-color: white;
  }
  
  .row_container_give_price {
    width: 100%;
    height: 100px;
    border: #00A550 solid 0.5px;
    margin-left: 0px !important;
    -webkit-box-shadow: 0 0 5px 2px #D3D3D3 !important;
            box-shadow: 0 0 5px 2px #D3D3D3 !important;
    align-items: center;
  }
  
  .row_container_give_price_label {
    color: #A1A0AE;
    font-family: 'DINPro', sans-serif !important;
  }
  
  .price_input {
    width: 100%;
    // height: 5.08vh !important;
    border: #012509 solid 1px;
    border-radius: 4px;
  }
  
  .price_unit_selection {
    width: 100%;
    // height: 5.08vh !important;
    border: #012509 solid 1px;
    border-radius: 4px;
  }
  
  .information_project_input_area {
    width: 100%;
    height: 20.08vh !important;
    border: #00A550 solid 0.5px;
    -webkit-box-shadow: 0 0 5px 2px #D3D3D3 !important;
            box-shadow: 0 0 5px 2px #D3D3D3 !important;
  }
  
  .information_project_tips {
    color: #A1A0AE;
    font-family: 'DINPro', sans-serif !important;
  }

  #fee-title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bold;
    margin-top: 16px;
  }
  
  .text_cs {
    font-size: 11px !important;
    font-family: 'DINPro', sans-serif !important;
    color: #A1A0AE;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  
  .comfirm_button {
    background-color: #00A550;
    width: 90%;
    height: 36px;
    border-radius: 6px;
    font-size: 10px !important;
    font-family: 'DINPro', sans-serif !important;
    font-weight: bold;
  }
  
  .cancel_button {
    background-color: #FF2323;
    width: 90%;
    height: 36px;
    border-radius: 6px;
    font-size: 10px !important;
    font-family: 'DINPro', sans-serif !important;
    font-weight: bold;
  }
  
  .fee_container {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .fee_heading {
    background-color: #FFF000;
    font-weight: bold;
    width: 262px;
    height: 26px;
    margin: auto;
  }
  
  .circle_green_coin {
    width: 124px;
    height: 124px;
    border-radius: 62px;
    color: #00A550;
    margin: auto;
    font-size: 50px;
    font-weight: bold;
    font-family: 'DINPro', sans-serif;
    border: dashed #FFFFFF 1px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  
  .fee_footer_notify {
    display: flex;
    flex-direction: row;
  }
  
  .fee_footer_notify img {
    width: 27px;
    height: 27px;
  }
  
  .btn_add_coin_fee {
    width: 168px;
    height: 40px;
    border-radius: 6px;
    background-color: #029AFF;
    color: #FFFFFF;
    font-weight: bold;
    font-family: 'DINPro', sans-serif;
    border: none;
    margin: auto;
  }
};
@primary-color: #1DA57A;