.login_container {
  position: relative;
  background-color: white !important;
  width: 100%;
  height: 66.61vh !important;
  display: flex;
  align-items: center;
  margin: auto !important;
  margin-top: 55px !important;
}

.layout_login {
  background-color: white !important;
}

.layout_title {
  color: #232735;
  font-size: 17px;
  font-weight: bold;
  font-family: 'DINPro', sans-serif;
}

.layout_subtitle {
  color: #4D4F5C;
  font-size: 14px;
  font-family: 'DINPro', sans-serif;
}

.form_input_item {
  border-radius: 5px;
  background-color: #EDEDED !important;
}

.form_input_item input {
  background-color: #EDEDED !important; 
}

.footer_item {
  display: flex !important;
  justify-content: center;
}

.checkbox_label {
  color: #4D4F5C;
  font-size: 14px;
  font-family: 'DINPro', sans-serif;
  margin-left: 6px;
}

.forget_password_button {
  background: transparent;
  border: none;
  color: #4D4F5C;
  font-size: 14px;
  font-family: 'DINPro', sans-serif; 
}

.login_button {
  width: 9.66vw;
  @media only screen and (min-width: 414px) and (max-width: 1023px) {
    width: 200px !important;
  }
  background-color: #00A550;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  font-family: 'DINPro', sans-serif; 
  -webkit-box-shadow: 0 0 5px 2px #D3D3D3 !important;
          box-shadow: 0 0 5px 2px #D3D3D3 !important;
}

.register_button {
  width: 9.66vw;
  height: 32px;
  @media only screen and (min-width: 414px) and (max-width: 1023px) {
    width: 200px !important;
  }
  background-color: #FFF000;
  border-radius: 5px;
  color: #012509;
  font-weight: bold;
  font-family: 'DINPro', sans-serif; 
  -webkit-box-shadow: 0 0 5px 2px #D3D3D3 !important;
          box-shadow: 0 0 5px 2px #D3D3D3 !important;
}

