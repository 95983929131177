.header_form {
  background-color: transparent;
  border-bottom: #707070 solid 1px;
}

.form_title {
  color: #012509 !important;
  font-size: 17px;
  margin-top: 11px;
  font-weight: bold;
}

.form_item_select {
  border: #00A550 solid 0.5px;
  -webkit-box-shadow: 0 0 5px 2px #D3D3D3 !important;
          box-shadow: 0 0 5px 2px #D3D3D3 !important;
}

.contact_info_form_header {
  background-color: #00A550;
}

.contact_info_form_header p {
  color: white;
  font-weight: bold;
  font-size: 14px;
  font-family: 'DINPro', sans-serif;
}

.contact_info_form_content {
  background-color: #F9F9F9;
}