@import '../../share.less';

.product_title {
  font-size: 25px !important;
  position: relative;
  text-align: left;
}

.btn_buy_now {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 139px;
  font-size: 14px;
}

.mg-t-15 span {
  color: #FFFFFF;
}

.product_detail_title {
  text-align: left;
}

.product_gallery {
  .ant-carousel {
    .product_gallery_carousel {
      .item {
        // width: 650px;
        @media @mobile {
          height: 375px;
          img {
            width: 100%;
            height: 100%;
            margin: auto !important;
          }
        }
        @media @desktop {
          width: 600px;
          height: 600px;
          img {
            width: 100%;
            height: 100%;
          }
        }
    
      }
    }
  }
}

@media @mobile {
  figure {
    width: 100% !important;
    img {
      width: 100% !important;
      height: auto !important;
    }
  }
}
@primary-color: #1DA57A;