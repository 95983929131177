@desktop:   ~"(min-width: 960px)";
@mobile:    ~"(min-width: 320px) and (max-width: 768px)";


@media @desktop {
  .row_news_3 {
    max-width: 1306px;
    padding: 0 24px;
    margin: auto;
    ._item {
      height: 240px;
      width: 611px;
      margin: auto;
      align-items: center;
      text-align: center;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media @mobile {
  .row_news_3 {
    ._item {
      height: 240px;
      width: 200px;
      margin: auto;
      align-items: center;
      text-align: center;
      ._text {
        background: none !important;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@primary-color: #1DA57A;