@desktop:   ~"(min-width: 960px)";
@mobile:    ~"(min-width: 320px) and (max-width: 768px)";

@media @mobile {
  .history_data_container {
    display: flex;
    width: 100% !important;
    height: 160px;
    background-color: #CCD874;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 16px;
  }
  .data_number_display {
    font-family: 'DINPro' sans-serif;
    font-size: 24px;
    color: #00A550;
  }
  .data_number_display {
    font-family: 'DINPro' sans-serif;
    font-size: 24px;
    color: #00A550;
  }

  .coin_card {
    height: auto;
    margin-bottom: 16px;
  }

  .coin_display {
    background: none !important;
    p {
      color: #00A550;
    }
  }

  .table_container {
    width: 100%;
    margin: auto;
    .ant-table-wrapper {
      width: 100%;
      overflow: scroll;
      overflow-y: visible;
    }
  }
}

@media @desktop {
  .history_data_container {
    display: flex;
    width: 54.905vw;
    height: 160px;
    background-color: #CCD874;
    justify-content: space-around;
    align-items: center;
    margin-left: 3.807vw;
  }
  
  .data_number_display {
    font-family: 'DINPro' sans-serif;
    font-size: 40px;
    color: #00A550;
  }
  
  .coin_card {
    margin-left: 5.124vw;
  }
  
  .data_number_label {
    font-family: 'DINPro' sans-serif;
    font-weight: bold;
    font-size: 17px;
    color: #FFFFFF;
  }
  
  .table_title {
    margin-top: 47px;
    padding-bottom: 22px;
    width: 12.445vw;
  }
  
  .table_container {
    width: 73.353vw;
    margin: auto;
  }
}
@primary-color: #1DA57A;