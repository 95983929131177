@desktop:   ~"(min-width: 960px)";
@mobile:    ~"(min-width: 320px) and (max-width: 768px)";

@media @desktop {
  #partner-logo-slider-wrapper {
    background-image: url('../../../assets/partner.jpg');
    background-size: cover;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 19px;
    padding-bottom: 22px;
    margin: 0px !important;
    #title-wrapper {
      #title {
        color: #FFFFFF;
        font-size: 28px;
        font-weight: bold;
        display: inline-block;
        box-shadow: 1 4px 4px rgba(0, 0, 0, 0.5);
        // flex-direction: row;
        align-items: center;
        #big-part-title {
          display: inline-block;
          color: #FFFFFF;
          font-size: 40px;
          font-weight: bold;
          margin-bottom: 0px;
          margin: 0 8px;
        }
      }
    }

    #col-partner-carousel-wrapper {
      // padding-left: 50px !important;
      margin-bottom: 26px;
      .slick-next {
        right: -15px;
      }
      .slick-prev {
        left: -15px;
      }
      .partner-carousel {
        margin: auto;
        padding-left: 56px;
        max-width: 1404px;
        .logo-wrapper {
          img {
            width: 157px;
            border-radius: 79px;
          }
        }
      }
    }

    .slider-btn {
      height: 51px;
      background-color: #FFF000;
      border-radius: 25px;
      color: #012509;
      font-weight: bold;
      font-size: 17px;
    }
  }
}

@media @mobile {
  #partner-logo-slider-wrapper {
    background-image: url('../../../assets/partner.jpg');
    background-size: cover;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 19px;
    padding-bottom: 22px;
    margin: 0px !important;
    #title-wrapper {
      #title {
        color: #FFFFFF;
        font-size: 18px;
        font-weight: bold;
        display: inline-block;
        // flex-direction: row;
        align-items: center;
        #big-part-title {
          display: inline-block;
          color: #FFFFFF;
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 0px;
          margin: 0 8px;
        }
      }
    }

    #col-partner-carousel-wrapper {
      // padding-left: 50px !important;
      margin-bottom: 26px;
      .partner-carousel {
        margin: auto;
        padding-left: 46px;
        max-width: 1404px;
        width: 96%;
        .logo-wrapper {
          img {
            width: 100px;
            border-radius: 50px;
          }
        }
      }
    }

    .slider-btn {
      height: 71px;
      width: 100%;
      background-color: #FFF000;
      border-radius: 45px;
      color: #012509;
      font-weight: bold;
      font-size: 5px !important;
      word-wrap: break-word;
      white-space: normal;
    }
  }
}
@primary-color: #1DA57A;