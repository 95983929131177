.__title {
  margin-top: 42px;
  margin-bottom: 17px;
  text-align: left;
}

.partner_col {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 30px;
}

._list_content {
  display: flex;
  flex-direction: row;
  width: 978px;
  height: 34px;
  align-items: flex-start;
  margin-bottom: 36px;
}

._list_content p {
  width: 938px;
  font-size: 15px;
  font-family: 'DINPro', sans-serif;
}

.btn_border_radius_green {
  width: 183px;
  height: 36px;
  border-radius: 15px;
  font-weight: bold;
  font-family: 'DINPro', sans-serif;
  color: #FFFFFF;
  background-color: #00A550;
  border: none;
  margin-bottom: 31px;
}