.btn_post_estimate {
  background-color: #00A550 !important;
  border-radius: 5px;
  width: auto !important;
  height: 30px !important;
  margin-right: 10px;
  color: white;
  font-family: 'DINPro', sans-serif;
  border: none;
  padding-left: 15px;
  padding-right: 15px;
}

.btn_waiting_estimate {
  background-color: white !important;
  border-radius: 5px;
  border: 1px solid black;
  width: auto !important;
  height: 30px !important;
  margin-right: 10px;
  color: black;
  font-family: 'DINPro', sans-serif;
  padding-left: 15px;
  padding-right: 15px;
}

.btn_cancel {
  background-color: #FF6262;
  border-radius: 5px;
  width: 82px !important;
  height: 30px !important;
  font-family: 'DINPro', sans-serif;
}