.modal_login_container {
  background-color: white !important;
  display: flex;
  align-items: center;
  margin: auto !important;
  margin-bottom: 24px !important;
  // margin-top: 55px !important;
}

.layout_login {
  background-color: white !important;
}

.layout_title {
  color: #232735;
  font-size: 17px;
  font-weight: bold;
  font-family: 'DINPro', sans-serif;
}

.layout_subtitle {
  color: #4D4F5C;
  font-size: 14px;
  font-family: 'DINPro', sans-serif;
}

.modal_form_input_item {
  border-radius: 5px;
  background-color: #EDEDED !important;
  input {
    background-color: #EDEDED !important;
  }
}

.footer_item {
  display: flex !important;
  justify-content: center;
}

.checkbox_label {
  color: #4D4F5C;
  font-size: 14px;
  font-family: 'DINPro', sans-serif;
  margin-left: 6px;
}

.forget_password_button {
  background: transparent;
  border: none;
  color: #4D4F5C;
  font-size: 14px;
  font-family: 'DINPro', sans-serif; 
}

.modal_login_button {
  background-color: #00A550;
  border-radius: 5px;
  color: white !important;
  font-weight: bold;
  font-family: 'DINPro', sans-serif; 
  -webkit-box-shadow: 0 0 5px 2px #D3D3D3 !important;
          box-shadow: 0 0 5px 2px #D3D3D3 !important;
}

.modal_register_button {
  background-color: transparent;
  border: none;
  font-weight: bold;
  font-family: 'DINPro', sans-serif; 
}


@primary-color: #1DA57A;