@desktop:   ~"(min-width: 960px)";
@mobile:    ~"(min-width: 320px) and (max-width: 768px)";

@media @desktop {
  //CUSTOMER ORDERING SERVICE LIST
  //-----------------------------//
  #customer-ordering-service-wrapper {
    max-width: 1326px;
    padding: 0px 57px;
    margin: auto !important;
    margin-top: 24px !important;
    margin-bottom: 124px !important;
    h3 {
      background-color: #00A550;
      height: 70px;
      text-align: left;
      color: #FFFFFF;
      padding: 23px 8px;
      font-weight: bold;
      font-size: 17px;
    }
    .custom-table {
      background-color: #FFFFFF;
    }
  }

  //PARTNER ORDERED SERVICE//
  //--------------------------//
  #partner-ordered-service-wrapper {
    max-width: 1326px;
    padding: 0px 57px;
    margin: auto !important;
    margin-top: 24px !important;
    margin-bottom: 124px !important;
    h3 {
      background-color: #00A550;
      height: 70px;
      text-align: left;
      color: #FFFFFF;
      padding: 23px 8px;
      font-weight: bold;
      font-size: 17px;
    }
    .custom-table {
      background-color: #FFFFFF;
    }
  }

  //PARTNER LIST QUOTATION
  //------------------------//
  #partner-list-quotation-wrapper {
    max-width: 1326px;
    padding: 0px 57px;
    margin: auto !important;
    margin-top: 24px !important;
    margin-bottom: 124px !important;
    h3 {
      background-color: #00A550;
      height: 70px;
      text-align: left;
      color: #FFFFFF;
      padding: 23px 8px;
      font-weight: bold;
      font-size: 17px;
    }
    .custom-table {
      background-color: #FFFFFF;
    }
  }
}

@media @mobile {
  //CUSTOMER ORDERING SERVICE LIST
  //-----------------------------//
  #customer-ordering-service-wrapper {
    width: 100%;
    padding: 0px 12px;
    margin: auto !important;
    margin-top: 24px !important;
    margin-bottom: 124px !important;
    h3 {
      background-color: #00A550;
      height: 70px;
      text-align: left;
      color: #FFFFFF;
      padding: 23px 8px;
      font-weight: bold;
      font-size: 17px;
    }
    .custom-table {
      width: 100% !important;
      overflow-x: scroll;
      background-color: #FFFFFF;
      .ant-table-cell {
        a {
          button {
            height: auto !important;
          }
        }
      }
    }
  }

  //PARTNER ORDERED SERVICE//
  //--------------------------//
  #partner-ordered-service-wrapper {
    width: 100%;
    padding: 0px 12px;
    margin: auto !important;
    margin-top: 24px !important;
    margin-bottom: 124px !important;
    h3 {
      background-color: #00A550;
      height: 70px;
      text-align: left;
      color: #FFFFFF;
      padding: 23px 8px;
      font-weight: bold;
      font-size: 17px;
    }
    .custom-table {
      width: 100% !important;
      overflow-x: scroll;
      background-color: #FFFFFF;
      .ant-table-cell {
        a {
          button {
            height: auto !important;
          }
        }
      }
    }
  }

  //PARTNER QUOTATION
  //--------------------------//
  #partner-list-quotation-wrapper {
    width: 100%;
    padding: 0px 12px;
    margin: auto !important;
    margin-top: 24px !important;
    margin-bottom: 124px !important;
    h3 {
      background-color: #00A550;
      height: 70px;
      text-align: left;
      color: #FFFFFF;
      padding: 23px 8px;
      font-weight: bold;
      font-size: 17px;
    }
    .custom-table {
      width: 100% !important;
      overflow-x: scroll;
      background-color: #FFFFFF;
      .ant-table-cell {
        a {
          button {
            height: auto !important;
          }
        }
      }
    }
  }
}
@primary-color: #1DA57A;