$green: #00A550;
$yellow:#FFF000;
$yellow_2:#CCD874;
$yellow_3: #FFC400;
$blue: #029AFF;
$blue_2: #F1F9FF;
$blue_border: #BCE0FD;
$gray: #D3D3D3;
$gray_2:#f9f9f9;
$gray_3:#EDEDED;
$gray_text: #707070;
$black: #000000;
$white: #ffffff;
$bg: #012509;
$red: #D60000;
// End Variable
@font-face {
    font-family: 'DINPro', sans-serif;
    src: url(/fonts/DINPro-Medium-tr.ttf) format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'DINPro', sans-serif;
    src: url(/fonts/DINPro-Light-tr.ttf) format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'DINPro', sans-serif;
    src: url(/fonts/DINPro-Bold-tr.ttf) format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'DINPro', sans-serif;
    src: url(/fonts/DINPro-Black-tr.ttf) format('truetype');
    font-weight: 700;
}

body {
    background: $bg;
    font-family: 'DINPro', sans-serif;
    color: $white;
}

* {
    font-family: 'DINPro', sans-serif;
    font-size: 14px;
}

.black {
    color: $black;
}

.top_bar {
    display: block;
    text-align: right;
    float: right;
    padding: 5px 0 0;
    width: 100%;
    margin-bottom: -25px;
    .btn-sm {
        background: none;
        border-radius: 30px;
        padding: 2px 15px;
        font-size: 12px;
    }
    .top_login {
        display: inline-block;
        margin-right: 40px;
        a {
            color: $yellow;
            font-size: 15px;
            font-weight: 600;
            margin-right: 20px;
        }
    }
}

.container_header {
    background-color: $bg;
}

.header_main {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    ._menu {
        width: calc(50% - 68px);
        display: flex;
        justify-content: space-evenly;
        position: relative;
        border-top: 1px solid $green;
        border-bottom: 1px solid $green;
        padding: 10px;
        &._left {
            background: url(./assets/bg_mn_left.png);
            background-repeat: no-repeat;
            background-position: right -6px center;
            background-size: inherit;
        }
        &._right {
            background: url(./assets/bg_mn_right.png);
            background-repeat: no-repeat;
            background-position: left -6px center;
            background-size: inherit;
        }
        a {
            text-align: center;
            color: $white;
            font-weight: 600;
            font-size: 20px;
            padding: 5px 15px;
            border-radius: 50px;
            &:hover {
                background: $green;
            }
        }
        .active {
            font-weight: 600;
            font-size: 20px;
            background: $green;
            padding: 5px 15px;
            border-radius: 50px;
        }
    }
}

.show_sm {
    display: none;
}

.form_search {
    position: absolute;
    right: 0;
    width: 0;
    overflow: hidden;
    transition: all 0.4s;
    display: flex;
    input {
        width: calc(100% - 26px);
        font-size: 16px;
        line-height: 20px;
        height: 30px;
        padding: 2px 10px;
        display: inline-block;
        border-radius: 5px 0 0 5px;
        border: 0;
    }
    ._btn_search {
        width: 30px;
        height: 30px;
        display: inline-block;
        border: 0;
        background: url(./assets/search.png), $blue;
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: center;
        border-radius: 0 5px 5px 0;
    }
}

.search_icon {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 17px;
    width: 27px;
    height: 27px;
    background: url(./assets/search.png);
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    &:hover .form_search {
        width: 200px;
    }
}


/* Slider home */

.home_slider {
    ._item {
        position: relative;
        .bg_img {
            position: relative;
            z-index: 1;
            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: #00000052;
            }
        }
        img {
            width: 100%;
        }
        ._content {
            position: absolute;
            left: 0;
            top: 0;
            width: 25vw;
            height: 100%;
            background: $gray_2;
            display: flex;
            align-items: center;
            padding-left: calc((100% - 1235px) / 2);
            padding-right: 20px;
            z-index: 2;
            * {
                color: $black;
            }
        }
        ._content_category {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            z-index: 2;
            .container {
                padding: 0;
                >div {
                    max-width: 650px;
                }
            }
        }
    }
    .slick-dots {
        position: absolute;
        bottom: 10px;
        right: 20px;
        list-style: none;
        margin: 0;
        li {
            line-height: 0;
        }
        button {
            font-size: 0;
            width: 10px;
            height: 10px;
            margin-top: 3px;
            background: $white;
            border: 0;
        }
        .slick-active button {
            background: $green;
        }
    }
}

.btn-main {
    display: inline-block;
    border-radius: 50px;
    background: $green;
    color: $white !important;
    padding: 5px 15px;
}

.slick-arrow {
    position: absolute;
    width: 42px;
    height: 42px;
    z-index: 99;
    top: calc(50% - 21px);
    border: 0;
    border-radius: 50%;
    font-size: 0;
    outline-style: none !important;
    &.slick-prev {
        left: 15px;
        background: url(./assets/prev.png), #ffffff59;
        background-repeat: no-repeat;
        background-position: center;
    }
    &.slick-next {
        right: 15px;
        background: url(./assets/next.png), #ffffff59;
        background-repeat: no-repeat;
        background-position: center;
    }
}

// Button
.btn,
.btn:focus {
    outline-style: none;
    box-shadow: none !important;
    border: 0;
    border-radius: 5px;
    font-size: 16px;
    line-height: 16px;
    padding: 12px 15px;
    font-weight: 600;
    height: 39px;
    &.auto {
        min-width: auto;
    }
    &.green {
        background-color: $green;
        color: $white;
        &.outline {
            background: $white;
            border: 1px solid $green;
            color: $green;
            &:hover {
                background: $green;
                color: $white;
            }
        }
    }
    &.white {
        background-color: $white;
        border: 1px solid $black;
        border-radius: 5px;
    }
    &.red {
        background-color: #FF4646;
        color: $black;
    }
    &.radius_tr_bl {
        border-radius: 0 10px 0 10px;
    }
    &.small {
        font-size: 11px;
        padding: 6px 12px;
    }
    &.text_black {
        color: $black;
    }
    &.xs {
        padding: 2px 10px;
        border-radius: 5px;
        font-size: 10px;
        height: auto;
    }
    &.text_blue {
        color: $blue;
    }
    &.btn-sm {
        padding: 2px 15px;
        font-size: 12px;
    }
    &.h36 {
        height: 36px;
        font-size: 14px;
        line-height: 12px;
        padding-top: 10px;
    }
    &.full {
        width: 100%;
    }
    &.large {
        padding: 15px 20px;
        height: 69px;
    }
    &.yellow {
        background-color: $yellow;
        color: $bg;
    }
    &.pd-l-0 {
        padding-left: 0 !important;
    }
    &.yellow_2 {
        background: $yellow_2;
        color: $gray_text;
        &.outline {
            background: $white;
            border: 1px solid $yellow_2;
            color: $yellow_2;
            &:hover {
                background: $yellow_2;
                color: $white;
            }
        }
    }
    &.blue {
        background: $blue;
        color: $white;
    }
    &.red {
        background: $red;
        color: $white;
        &.buy {
            min-width: 228px;
        }
    }
    &.gray {
        background-color: $gray;
        color: $black;
    }
    &.block {
        display: block;
    }
    &.radius_50 {
        border-radius: 50px;
    }
    &.icon {
        padding-left: 35px;
        background: url(./assets/icon/new_post.svg), $blue;
        background-repeat: no-repeat;
        background-size: 22px;
        background-position: 10px center;
    }
    &.new_post {
        padding-left: 21px;
        background: url(./assets/icon/new_post.svg), #029AFF;
        background-repeat: no-repeat;
        background-size: 25px;
        background-position: 12px center;
    }
    &.arrow_left {
        padding-left: 40px;
        background-image: url(./assets/icon/arrow.png);
        background-repeat: no-repeat;
        background-size: 22px;
        background-position: 10px center;
    }
    &.arrow_right {
        padding-right: 40px;
        background-image: url(./assets/icon/arrow_right.png);
        background-repeat: no-repeat;
        background-size: 22px;
        background-position: right 10px center;
    }
    &.add_cart {
        background-color: $blue;
        color: $white;
    }
    &.radius-0 {
        border-radius: 0;
    }
    &.shadow {
        box-shadow: 0 0 5px 2px $gray !important;
    }
    &.min_w162 {
        min-width: 162px;
    }
}

.form-control {
    height: 39px;
}

// Product
.col_filter_product {
    display: flex;
    width: 100%;
    margin-top: 35px;
    align-items: flex-end;
    justify-content: space-between;
}

.filter_product {
    width: auto;
    min-width: 60%;
    display: table;
    ._col {
        display: table-cell;
        vertical-align: bottom;
        padding: 0 10px;
        .btn {
            min-height: 39px;
            min-width: 90px;
            padding: 5px;
        }
    }
}

.list_product {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 15px;
    width: 100%;
    margin-top: 20px;
}

.item_product {
    height: 345px;
    background: $white;
    margin-bottom: 20px;
    img {
        object-fit: cover;
        width: 100%;
    }
    ._content {
        padding: 5px 10px;
        ._title {
            text-align: left;
            font-size: 15px;
            font-weight: 600;
            display: block;
            color: $black;
            width: 100%;
            margin-bottom: 12px;
            max-height: 22px;
            overflow: hidden;
            cursor: pointer;
        }
        ._price-wrap {
            display: flex;
            flex-direction: column;
            width: 50%;
        }
        ._price {
            display: inline-block;
            color: $green;
            font-size: 18px;
            font-weight: 600;
            text-align: left;
        }
        ._old {
            font-size: 14px;
            bottom: 3px;
            font-weight: 600;
            color: #FF6565;
            text-align: left;
            
        }
        .btn {
            float: right;
        }
    }
}

.text- {
    &center {
        text-align: center;
    }
    &right {
        text-align: right;
    }
    &left {
        text-align: left;
    }
}

.clear-both {
    clear: both;
}

.sec_partner {
    width: 100%;
    background: url(./assets/partner.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px 0;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #ffffff4a;
        z-index: 0;
    }
    .container {
        z-index: 1;
    }
    ._title {
        text-align: center;
        color: $bg;
        font-size: 28px;
        font-weight: 600;
        .large {
            font-size: 40px;
        }
    }
    .ant-carousel   {
        // display: flex;
        // flex-direction: row;
        // margin: 30px 0;
        .slick-slide {
            // width: 100%;
            ._item {
                width: 246px;
                height: 100%;
                border-radius: 123px;
                text-align: center;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    margin: 0 auto;
                    max-width: 160px;
                }
            }
        }
        // .slick-arrow.slick-prev {
        //     left: -45px;
        // }
        // .slick-arrow.slick-next {
        //     right: -45px;
        // }
    }
}

.row_service {
    margin: 15px -30px;
}

.item_service {
    padding: 20px 15px;
    margin-top: 0px !important;
    border-bottom: white solid 1px;
    ._title {
        font-size: 17px;
        font-weight: 600;
        color: $white;
        background: $green;
        display: block;
        width: 100%;
        padding: 10px 15px;
        text-align: left;
    }
    ._content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 160px;
        background: $white;
        >div {
            // display: table-cell;
            padding: 15px;
        }
        ._text {
            text-align: left;
            color: $black;
        }
        ._btn {
            text-align: right;
            .btn {
                display: block;
                min-width: 150px;
                width: 100%;
            }
        }
    }
    ._info {
        margin: 0;
        padding: 10px;
        background: $white;
        border-top: 1px solid $gray;
        border-bottom: 1px solid $gray;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    ._category {
        display: table;
        width: 100%;
        background: $white;
        padding: 10px 15px;
        * {
            color: $black;
        }
        >div {
            display: table-cell;
            text-align: left;
            a {
                color: $blue;
                padding-right: 20px;
                display: inline-block;
            }
            p {
                margin-bottom: 0px;
            }
        }
        ._left {
            min-width: 160px;
            text-align: left;
        }
    }
}

._info {
    >div {
        padding-left: 30px;
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 25px;
    }
    ._location {
        background-image: url(./assets/icon/pin.png);
    }
    ._customer {
        background-image: url(./assets/icon/coppy.png);
    }
    ._date {
        background-image: url(./assets/icon/date.png);
        text-align: right;
    }
    * {
        color: $black;
    }
}

.col_line {
    display: block;
    width: 100%;
    height: 1px;
    background: $white;
}

.footer_main {
    background: $yellow_2;
    * {
        color: $black;
    }
    ._logo {
        max-width: 135px;
    }
}

.col_footer {
    margin-top: 40px;
    ._title {
        font-size: 20px;
        font-weight: 600;
    }
    li {
        list-style: none;
        margin: 0;
        padding: 0;
        a {
            font-size: 15px;
            padding-top: 10px;
            display: inline-block;
        }
    }
}

.row_copyright {
    padding-bottom: 20px;
    * {
        color: $white;
    }
    .left {
        line-height: 34px;
        font-size: 10px;
    }
    .right {
        ._title {
            color: $black;
            font-size: 17px;
            font-weight: 600;
        }
        a {
            margin-left: 15px;
            display: inline-block;
        }
        img {
            width: 34px;
            height: 34px;
        }
    }
}

// Category page
.title_sec {
    color: white;
    font-size: 25px;
    font-weight: 600;
    padding-top: 15px;
    margin-top: 0px;
    position: relative;
    margin-bottom: 0px;
    &.border_top::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 120px;
        height: 3px;
        background: $white;
    }
    &.mg-t-5 {
        margin-top: 5px;
    }
    &.center {
        text-align: center;
    }
    &.border_bottom {
        padding-bottom: 10px;
        border-bottom: 1px solid $gray_text;
        margin-bottom: 15px;
    }
}

.category_pagination {
    justify-content: center;
    flex-wrap: 10px;
    margin-top: 40px;
    margin-bottom: 50px;
    >li {
        margin: 0 3px;
        min-width: 33px;
        border-radius: 4px !important;
        .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
            max-width: 120px !important;
        }
        .dropdown.bootstrap-select .btn {
            background: $white;
            color: $black;
        }
    }
    .page-link {
        border-radius: 2px;
        color: $black;
        display: block;
        text-align: center;
        height: 35px;
        min-width: 35px;
        &.active,
        &:hover {
            color: $white;
            background-color: $green;
            border-color: $green;
        }
    }
}

.row_news_3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 40px;
    padding: 0;
    ._item {
        position: relative;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        ._text {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding: 15px;
            color: $yellow_3;
            font-size: 22px;
            font-weight: 600;
        }
        .btn {
            position: absolute;
            bottom: 15px;
            right: 30px;
        }
    }
    &.service {
        margin: 20px 0;
        padding: 20px 15px;
    }
}

.dropdown-menu.show {
    width: 100%;
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:hover {
    background-color: $gray;
}

// single product
.breadcrumb {
    display: table;
    width: 100%;
    padding: 0;
    background: none;
    margin-top: 30px;
    >div {
        display: table-cell;
    }
    .content {
        display: block;
        background: $white;
        margin-right: 30px;
        border-radius: 0;
        overflow: hidden;
        -webkit-box-shadow: 0 0 5px $white;
        box-shadow: 0 0 10px 1px $white;
        a {
            color: $black;
            font-size: 15px;
            padding: 6px 15px;
            font-weight: 500;
            display: inline-block;
            height: 39px;
        }
        span {
            color: $black;
            font-size: 15px;
        }
    }
    .post_product {
        width: 180px;
        min-width: 180px;
        a {
            width: 100%;
            padding-right: 0;
            padding-left: 20px;
        }
    }
}

.main_product {
    background-color: $white;
    padding-top: 20px;
    padding-bottom: 20px;
    * {
        color: $black;
    }
}

.product_info {
    max-width: 582px;
    .title {
        font-size: 25px;
        font-weight: 600;
        padding-bottom: 10px;
        margin-bottom: 25px;
        border-bottom: 1px solid $gray;
    }
    .border_top {
        margin-top: 15px;
        padding-top: 15px;
        border-top: 2px dashed $gray;
    }
    .price {
        margin-left: 0;
        margin-right: 0;
        >div {
            padding-left: 0;
        }
        .lable {
            font-size: 10px;
            padding-bottom: 5px;
        }
        .value {
            font-size: 20px;
            font-weight: 600;
            color: $green;
        }
    }
}

.row_flex_2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.quantity_box {
    display: flex;
    align-items: center;
    margin-top: 10px;
    >span {
        padding-right: 15px;
    }
}

.quantity {
    display: table;
    margin-left: 15px;
    float: none;
    margin: auto;
    .icon {
        width: 30px;
        @media only screen and (min-width: 414px) and (max-width: 415px) {
            width: 15px;
        }
        height: 30px;
        background-color: $gray;
        color: $black;
        text-align: center;
        font-size: 20px;
        line-height: 28px;
        display: inline-block;
        float: left;
        cursor: pointer;
        &.plus {
            float: right;
        }
    }
    input.qty {
        text-align: center;
        width: 30px;
        line-height: 30px;
        background-color: $white;
        border: 0;
        color: $black;
        vertical-align: top;
        float: left;
    }
}

.mw_220 {
    max-width: 220px;
}

.poroduct_slide {
    margin-bottom: 5px;
    img {
        display: block;
        width: 100%;
        max-height: 400px;
        object-fit: cover;
    }
}

.poroduct_slide_nav {
    overflow: hidden;
    .slick-list {
        margin-left: -5px;
        width: calc(100% + 10px);
    }
    .item {
        margin: 5px;
    }
    .slick-arrow.slick-prev {
        background: url(./assets/icon/prev.png);
        background-repeat: no-repeat;
        background-position: center;
    }
    .slick-arrow.slick-next {
        background: url(./assets/icon/next.png);
        background-repeat: no-repeat;
        background-position: center;
    }
}

.product_sg_content {
    margin-top: 30px;
    >._title {
        display: block;
        width: 100%;
        padding: 15px;
        background: $green;
        color: $white;
        font-size: 25px;
        font-weight: 600;
    }
    >._content {
        max-width: 900px;
        padding: 20px;
        p {
            margin-bottom: 15px;
        }
        img {
            margin-bottom: 15px;
        }
    }
}

.p_relative {
    margin-top: 50px;
    margin-bottom: 80px;
    padding-top: 15px;
    padding-bottom: 15px;
    background: $white;
    >._title {
        display: inline-block;
        width: auto;
        padding: 15px;
        background: $green;
        color: $white;
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 20px;
    }
}

.bg_white {
    background: $white;
    * {
        color: $black;
    }
}

.cart_content {
    margin-top: 0;
}

.strip_bar {
    display: table;
    width: 100%;
    max-width: 340px;
    margin: 30px auto;
    .item {
        display: inline-block;
        float: left;
        width: 46px;
        text-align: center;
        position: relative;
        margin-bottom: 50px;
        &.center {
            width: calc(100% - 100px);
            width: -webkit-calc(100% - 100px);
            text-align: center;
            &:before {
                content: "";
                height: 2px;
                background-color: #d4eeff;
                display: inline-block;
                width: 50%;
                position: absolute;
                top: calc(50% - 2px);
                transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                left: 0;
                z-index: 0;
            }
            &:after {
                content: "";
                height: 2px;
                background-color: #d4eeff;
                display: inline-block;
                width: 50%;
                position: absolute;
                top: calc(50% - 2px);
                transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                right: 0;
                z-index: 0;
            }
        }
        .sline {
            display: inline-block;
            width: 50px;
            height: 50px;
            background-color: #fff;
            border: 0;
            border-radius: 50%;
            line-height: 50px;
            text-align: center;
            color: $blue;
            font-weight: bold;
            position: relative;
            z-index: 2;
            &::before {
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background: $blue;
                transform: translate(-50%, -50%);
            }
            &.active {
                background: #d4eeff;
            }
        }
        .text {
            display: block;
            text-align: center;
            position: absolute;
            top: 110%;
            width: 120px;
            text-align: center;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            left: 50%;
            font-size: 12px;
            font-weight: bold;
            color: $blue;
        }
    }
}

// Product_4
.cart_product {
    width: 100%;
    margin: 0 auto;
    max-width: 1000px;
    border-collapse: separate;
    border-spacing: 0 10px;
    * {
        color: $black;
    }
    th {
        text-align: center;
        padding: 10px;
    }
    td {
        text-align: left;
        padding: 10px;
        border-top: 1px solid $blue_border;
        border-bottom: 1px solid $blue_border;
        &.left {
            text-align: left !important;
        }
        &.total {
            text-align: right;
        }
        &.dvt,
        &.price {
            font-weight: 600;
        }
        &.total {
            font-size: 20px;
            font-weight: 600;
            color: $blue;
        }
        &:first-child {
            border-left: 1px solid $blue_border;
        }
        &:last-child {
            border-right: 1px solid $blue_border;
        }
    }
    tr {
        &.top {
            border-color: $yellow_2;
            background: $yellow_2;
            th {
                font-size: 17px;
                padding-top: 15px;
                padding-bottom: 15px;
            }
        }
    }
    .quantity {
        margin: 0;
    }
    &.center td {
        text-align: center;
    }
}

.total_cart {
    margin: 0 auto;
    max-width: 1000px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: $blue_2;
    padding: 10px;
    margin-top: 30px;
    margin-bottom: 40px;
    .title {
        color: $blue;
        font-size: 20px;
        line-height: 38px;
        font-weight: 600;
    }
    .number {
        font-size: 25px;
        font-weight: 600;
        color: $red;
    }
}

.p_2_col {
    display: flex;
    .left {
        width: 90px;
        margin-right: 10px;
        img {
            width: 90px;
            height: 60px;
            object-fit: cover;
        }
        a {
            font-size: 10px;
            color: $blue;
            text-decoration: underline;
        }
    }
    .right {
        .title {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 15px;
        }
        * {
            font-size: 12px;
        }
        b {
            display: inline-block;
        }
    }
}

.row_checkout_2 {
    padding-bottom: 50px;
}

.login_form {
    max-width: 290px;
    margin: 10px auto;
    .title {
        font-size: 17px;
        font-weight: 600;
        margin-bottom: 5px;
    }
    input.form-control {
        margin-top: 10px;
    }
    .remember {
        display: flex;
        width: 100%;
        justify-content: space-between;
        a {
            color: $gray_text;
            &.active {
                color: $black
            }
        }
    }
    .form-check-label {
        color: $gray;
    }
}

div.form-group {
    margin-bottom: 15px;
    label {
        margin-bottom: 5px;
    }
}

input.form-control {
    background-color: $gray_3;
    border: 1px solid $gray_3;
    height: 38px;
}

.form_shipping {
    .title {
        font-size: 17px;
        font-weight: 600;
        margin-bottom: 5px;
    }
    ._box {
        max-width: 400px;
        padding-left: 50px;
        padding-top: 15px;
        padding-bottom: 40px;
    }
    &.payment {
        ._box {
            width: 100%;
            max-width: 100%;
        }
    }
}

.radio_custom {
    display: block;
    position: relative;
    float: left;
    width: 100%;
    padding-left: 0;
    input[type=radio] {
        position: absolute;
        visibility: hidden;
        &:checked~.check {
            border: 3px solid #7FC4FD;
        }
        &:checked~.check::before {
            background: $blue;
        }
    }
    label {
        display: block;
        position: relative;
        padding: 22px 0 20px 50px;
        margin: 10px auto;
        height: 20px;
        z-index: 9;
        cursor: pointer;
        -webkit-transition: all 0.25s linear;
    }
    .check {
        display: block;
        position: absolute;
        border: 3px solid #7FC4FD;
        border-radius: 100%;
        height: 30px;
        width: 30px;
        top: 30px;
        left: 0;
        z-index: 5;
        transition: border .25s linear;
        -webkit-transition: border .25s linear;
        &::before {
            display: block;
            position: absolute;
            content: '';
            border-radius: 100%;
            height: 14px;
            width: 14px;
            top: 50%;
            margin: auto;
            /* transition: background 0.25s linear; */
            /* -webkit-transition: background 0.25s linear; */
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.float-left {
    float: left;
}

// Service page
.banner_qc {
    position: relative;
    max-height: 300px;
    margin-top: 35px;
    overflow: hidden;
    img {
        width: 100%;
        height: auto
    }
    .content {
        position: absolute;
        top: 50%;
        left: 128px;
        width: 620px;
        max-width: 100%;
        transform: translateY(-50%);
        .title {
            color: $blue;
            font-size: 40px;
            font-weight: 600;
            margin-bottom: 25px;
        }
    }
}

.mw_1048 {
    max-width: 1048px;
}

.modal_login {
    background: $white;
    border-radius: 0;
    position: relative;
    display: grid;
    grid-template-columns: 50% 50%;
    box-shadow: 0px 0px 5px #fff;
    .left {
        position: relative;
        .close {
            content: '';
            position: absolute;
            top: 15px;
            right: 15px;
            width: 15px;
            height: 15px;
            background-image: url(./assets/icon/close_gray.png);
            background-repeat: no-repeat;
            background-size: cover;
            cursor: pointer;
        }
        .login_form {
            max-width: 290px;
            margin: 0;
            margin-left: 50px;
            padding: 20px 0;
        }
    }
    .right {
        background-image: url(./assets/login_bg.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        .left_center {
            position: absolute;
            left: 0;
            top: 50%;
            width: 126px;
            transform: translate(-50%, -50%);
        }
    }
}

.content_col_8 {
    padding: 0 60px;
    .title {
        font-size: 17px;
        font-weight: 600;
        text-align: center;
        display: block;
        width: 100%;
        padding: 10px 0;
        margin-bottom: 15px;
        border-bottom: 1px solid $gray;
    }
}

.row_form {
    >div {
        margin-top: 20px;
    }
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
    font-weight: 400;
}

.border-green {
    input,
    textarea,
    select,
    .btn.dropdown-toggle {
        border: 1px solid $green !important;
        border-radius: 0;
        box-shadow: 0 0 5px 2px $gray !important;
        background: $white;
        color: $gray_text;
        padding-left: 30px;
        padding-right: 30px;
        &:focus {
            border: 1px solid $green !important;
        }
    }
    .dropdown-item {
        padding: 5px 30px;
    }
    .dropdown-toggle {
        max-width: 300px;
    }
    textarea {
        min-height: 180px;
        padding-top: 20px;
    }
    .mw-330 {
        max-width: 330px;
    }
}

.tag {
    background: #E7E7E7;
    color: $green;
    height: 38px;
    line-height: 38px;
    display: inline-block;
    padding: 0 10px;
    border-radius: 12px;
    margin-right: 15px;
    margin-bottom: 15px;
    position: relative;
    .close {
        position: absolute;
        top: -5px;
        right: -6px;
        width: 15px;
        height: 15px;
        background: url(./assets/icon/close_gray.png), $gray_2;
        border-radius: 50%;
        background-size: 10px;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.add_tag {
    display: inline-block;
    input.text {
        width: 100px;
        height: 38px;
        border: 0 !important;
        border-radius: 12px !important;
        background: #E7E7E7;
        box-shadow: none !important;
        padding: 0 15px;
    }
    .btn,
    .btn:focus {
        margin-left: 8px;
        height: 38px;
        width: 38px;
        float: right;
        border-radius: 12px;
        background-image: url(./assets/icon/plus_white.svg);
        background-position: center;
        background-repeat: no-repeat;
    }
}

.radius-0 {
    .dropdown-menu.show {
        border-radius: 0;
    }
}

.checkbox_cs {
    display: block;
    font-size: 11px;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked~.checkmark {
            background-color: $yellow_2;
        }
        &:checked~.checkmark:after {
            display: block;
        }
    }
    .checkmark {
        position: absolute;
        top: 2px;
        left: 0;
        height: 27px;
        width: 27px;
        background-color: $white;
        border: 2px solid $yellow_2;
        border-radius: 5px;
        &:after {
            content: "";
            position: absolute;
            display: none;
        }
        &:after {
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
    &:hover input~.checkmark {
        background-color: $yellow_2;
    }
}

// single service
.single_service {
    margin-top: 20px;
    background-color: $white;
    >.title {
        font-size: 17px;
        font-weight: 600;
        padding: 10px 0;
        border-bottom: 1px solid $gray;
    }
    >.content {
        padding: 15px;
        display: grid;
        grid-template-columns: auto auto;
        p {
            margin-bottom: 10px;
        }
        .left {}
        .right {
            padding-left: 15px;
            width: 250px;
        }
    }
    .category {
        padding: 10px 0;
        margin-bottom: 20px;
        border-top: 1px solid $gray;
        border-bottom: 1px solid $gray;
    }
}

.col_align_center {
    align-items: center;
    text-align: center;
}

.align_center {
    display: inline-block;
    margin: 0 auto;
}

.partner_quote {
    padding: 30px 0;
    .row {
        padding: 0 30px;
        margin-top: 30px;
        &.top {
            margin-top: 15px;
            margin-bottom: -10px;
            .col {
                margin-left: -30px;
            }
        }
    }
    .left {}
}

.partner_info {
    display: flex;
    padding-top: 20px;
    border: 1px solid $green;
    padding: 20px 15px 0;
    >.left {
        width: 135px;
        img {
            width: 72px;
            height: 72px;
            object-fit: cover;
            border-radius: 50%;
        }
    }
    >.right {
        max-width: 100%;
        width: 730px;
        .btn {
            position: absolute;
            right: 0;
            top: 0;
            background: $yellow_2;
            padding: 4px 15px;
            border-radius: 5px;
            color: $gray_text;
        }
    }
    >div {
        position: relative;
        div,
        p {
            margin-bottom: 10px;
            color: $gray_text;
        }
    }
    .title {
        font-size: 18px;
        font-weight: 600;
    }
    .career_box {
        border-top: 1px solid $gray;
        padding: 10px 0;
    }
}

.career_box {
    margin-bottom: 0 !important;
    .item {
        display: inline-block;
        height: 25px;
        /* line-height: 38px; */
        /* border: 1px solid #029aff; */
        background-color: #029aff;
        padding: 0 5px;
        margin-left: 25px;
        color: white;
        border-radius: 5px;
        font-weight: bold;
    }
}

.rating {
    display: block;
    text-align: center;
    width: 72px;
    >.item {
        display: inline-block;
        float: none;
        width: 10px;
        height: 10px;
        background-image: url(./assets/icon/star_gray.svg);
        background-repeat: no-repeat;
        background-size: 10px;
        background-position: center;
        &.active {
            background-image: url(./assets/icon/star.svg);
        }
    }
    .text {
        font-size: 10px;
        color: $gray_text;
    }
    &.review {
        unicode-bidi: bidi-override;
        direction: rtl;
        text-align: center;
        >.item {
            width: 15px;
            height: 15px;
            background-size: 15px;
            position: relative;
            cursor: pointer;
            &:hover,
            &:hover~span {
                color: transparent;
            }
            &:hover:before,
            &:hover~span:before {
                content: "\2605";
                position: absolute;
                left: 0;
                width: 15px;
                height: 15px;
                background-size: 15px;
                background-image: url(./assets/icon/star.svg);
            }
        }
        .item:hover {
            background-image: url(./assets/icon/star.svg);
        }
    }
}

// 
.modal_feedback {
    background: $white;
    border-radius: 0;
    position: relative;
    display: grid;
    grid-template-columns: 50% 50%;
    box-shadow: 0px 0px 5px #fff;
    position: relative;
    border-radius: 5px;
    >.left {
        padding: 25px 15px;
        .avatar {
            text-align: center;
        }
        .rating {
            width: 100%;
        }
        .info {
            text-align: center;
            .title {
                font-size: 36px;
                font-weight: 600;
                margin-top: 15px;
            }
            >.sub {
                font-size: 16px;
                color: $gray_text;
            }
        }
        .career_box {
            margin-top: 25px;
            text-align: center;
            border-top: 1px solid $gray;
            border-bottom: 1px solid $gray;
            padding-top: 10px;
            padding-bottom: 25px;
            margin-bottom: 10px;
            .title {
                margin-bottom: 5px;
                font-size: 12px;
                color: $gray_text;
            }
            .item {
                margin: 5px;
                font-size: 12px;
            }
        }
        .content {
            * {
                font-size: 12px;
                color: $gray_text;
            }
            div,
            p {
                margin-bottom: 10px;
            }
        }
    }
    .review_form {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 5px;
        border-top: 1px solid #DADBE6;
        margin-top: 25px;
        >div {
            display: table-cell;
            width: auto !important;
        }
        .title {
            font-size: 14px;
            color: $gray_text;
        }
        .btn {
            font-size: 14px;
            line-height: 14px;
            height: 32px;
            background: #DADBE6;
            color: #545871;
        }
    }
    >.right {
        position: relative;
        padding: 40px 10px 10px 0;
        .close {
            content: '';
            position: absolute;
            top: 15px;
            right: 15px;
            width: 15px;
            height: 15px;
            background-image: url(./assets/icon/close_gray.png);
            background-repeat: no-repeat;
            background-size: cover;
            cursor: pointer;
        }
    }
}

.comment_box {
    background-color: $gray_2;
    padding: 90px 20px 20px;
    max-height: 545px;
    overflow-y: auto;
    .title {
        float: left;
    }
    .share {
        float: right;
        font-weight: 600;
    }
    form {
        clear: both;
        width: 100%;
        max-height: 570px;
        overflow: visible;
        position: relative;
        textarea,
        textarea:focus {
            width: 100%;
            max-width: 100%;
            height: 125px;
            min-height: 100px;
            margin-top: 20px;
            border: 1px solid $blue_border !important;
            padding: 30px;
            &::placeholder {
                color: $gray_text;
            }
        }
        .btn,
        .btn:focus {
            position: absolute;
            bottom: 15px;
            right: 20px;
            padding: 3px 15px;
        }
    }
}

.list_comment {
    .item {
        background: $white;
        padding: 15px 20px;
        margin-top: 15px;
        display: table;
        width: 100%;
        .img {
            width: 45px;
            display: table-cell;
            img {
                width: 30px;
                height: 30px;
                object-fit: contain;
                border-radius: 50%;
            }
        }
        .content {
            display: table-cell;
            >div {
                margin-bottom: 20px;
            }
            .name {
                display: inline-block;
                font-weight: 600;
            }
            .time {
                font-size: 12px;
            }
        }
    }
}

// ScrollBar
::-webkit-scrollbar {
    width: 12px;
    border-radius: 50px;
    background-color: red;
}


/* Track */

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $gray;
    border: 2px solid $gray;
    border-radius: 50px;
    background: $gray;
    width: 12px;
    padding: 2px;
    /*     border-radius: 10px; */
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: $white;
    border-radius: 50px;
    width: 8px;
    border: 2px solid $gray;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: $blue;
    width: 8px;
    border: 2px solid $gray;
}


/* ::-webkit-scrollbar-button{
	background-color: red;
} */

// page 6
.p6_service {
    .title {
        font-size: 17px;
        margin-top: 10px;
    }
    .service_name {
        font-size: 14px;
        color: $gray_text;
    }
    .row {
        margin: 0;
        margin-top: 15px;
        margin-bottom: 15px;
        padding: 10px 0 20px;
        border-top: 1px solid $gray;
        border-bottom: 1px solid $gray;
        >div {
            padding: 0;
        }
        .col-12,
        .col-10 {
            padding-left: 30px;
        }
    }
}

.text-blue {
    color: $blue;
}

.text-gray {
    color: $gray_text;
}

.flex_2 {
    display: flex;
    justify-content: space-between;
}

.partner_info .quote {
    padding-left: 40px;
    .box {
        display: block;
        &.hide {
            display: none;
        }
        .btn {
            min-width: 106px;
            &.cur {
                min-width: 70px;
                margin-left: 15px;
            }
            &.price {
                min-width: 140px;
            }
        }
    }
}

.table_2 {
    display: table;
    width: 100%;
    >div {
        display: table-cell;
        vertical-align: top;
        &:first-child {
            width: 210px;
        }
    }
}

.title_sec {
    font-size: 19px;
    font-weight: 600;
    padding: 10px 15px;
    margin-top: 0px;
    margin-bottom: 0px;
    display: block;
    width: 100%;
    margin: 0;
    color: white;
    &.bg_green {
        background-color: $green;
        color: $white;
    }
}

.notifi {
    margin: 15px 0;
    display: block;
    padding-left: 30px;
    position: relative;
    &.err::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        background-image: url(./assets/icon/err.png);
        background-repeat: no-repeat;
        background-size: 20px;
    }
}

.form_contact {
    padding: 0 40px 20px;
    background: #F9F9F9;
    .col {
        margin-top: 15px;
        label {
            display: block;
        }
        input {
            width: 300px;
            max-width: 100%;
            height: 39px;
            padding-left: 40px;
            padding-right: 15px;
        }
    }
}

input.icon {
    padding-left: 40px;
    position: relative;
    background-position: center left 10px;
    background-size: 17px auto;
    background-repeat: no-repeat;
    &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 40px;
        height: 100%;
    }
    &.name {
        background-image: url(./assets/icon/name.svg);
        background-size: 17px auto;
        background-position: center left 10px;
        background-repeat: no-repeat;
    }
    &.email {
        background-image: url(./assets/icon/email.svg);
        background-size: 17px auto;
        background-position: center left 10px;
        background-repeat: no-repeat;
    }
    &.pin {
        background-image: url(./assets/icon/pin.svg);
        background-size: 17px auto;
        background-position: center left 10px;
        background-repeat: no-repeat;
    }
    &.phone {
        background-image: url(./assets/icon/phone.svg);
        background-size: 17px auto;
        background-position: center left 10px;
        background-repeat: no-repeat;
    }
    &.web {
        background-image: url(./assets/icon/web.svg);
        background-size: 17px auto;
        background-position: center left 10px;
        background-repeat: no-repeat;
    }
}

.flex_5 {
    padding: 0 10px;
    .col {
        padding: 0 5px;
    }
}

.mw_1000 {
    max-width: 1000px;
    display: block;
    margin: 0 auto;
}

.box_feedback {
    width: 490px;
    max-width: 100%;
    height: 164px;
    padding: 20px 25px;
    border: 1px solid $blue_border;
    * {
        color: $black;
    }
}

.info_supplier {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 2px dashed $gray_text;
    >.title {
        font-size: 17px;
        margin-bottom: 15px;
    }
    input {
        width: 352px;
        max-width: 100%;
        &.w100 {
            width: 100%;
        }
    }
}

// 12 POST PRODUCT
.box_product_post {
    background-color: $gray_2;
    padding: 20px 25px;
    .dropdown-toggle {
        text-align: left;
        font-size: 12px;
        color: $gray_text;
        &::after {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
        }
    }
    .flex_4_pr {
        display: flex;
        justify-content: space-between;
        input {
            padding-left: 15px;
            padding-right: 15px;
        }
        .form-group {
            margin-bottom: 15px;
            &:nth-child(1) {
                width: 90px;
            }
            &:nth-child(4) {
                width: 90px;
            }
        }
    }
}

.input_file {
    margin-top: 10px;
    input[type='file'] {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    label {
        font-size: 14px;
        background: none;
        color: $black;
        padding-left: 60px;
        padding-top: 20px;
        font-weight: 600;
        cursor: pointer;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 35px;
            height: 23px;
            background-image: url(./assets/icon/input_file_cloud.png);
            background-size: 35px 23px;
        }
        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 26px;
            width: 35px;
            height: 23px;
            background-image: url(./assets/icon/input_file.png);
            background-size: 35px auto;
            background-repeat: no-repeat;
        }
    }
}

textarea {
    &.info_short {
        min-height: 86px;
    }
    &.info_long {
        min-height: 254px;
    }
}

.gril_3_col {
    margin-top: 15px;
    img {
        display: inline-block;
        width: 70px;
        height: 70px;
        object-fit: cover;
        margin-right: 15px;
        margin-bottom: 15px;
    }
}

// 9-10-11
.balance_service>.content {
    padding: 15px;
}

.balance_service>.content .left {
    width: 75%;
    float: left;
    padding: 0 15px;
}

.balance_service>.content .right {
    float: right;
    width: 25%;
    padding: 0 15px;
}

.balance_service {
    background: #ffffff;
}

.balance_service>.content .right {
    padding-left: 15px;
}

.balance_service>.content .right {
    padding-left: 15px;
}

.balance_service>.content .right ._info {
    background: #FFF000;
    padding: 10px 30px;
}

.balance_current {
    padding: 10px 15px;
    float: left;
    background: #FFF000;
}

.balance_service>.content .right ._info .left {
    width: 50%;
    float: left;
    font-weight: bold;
    padding: 30px 0;
}

.balance_current .left {
    width: 55%;
    float: left;
    font-weight: bold;
    padding: 20px 0;
    margin-right: 10px;
}

.balance_service .right .number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 38px;
    color: #fff;
    font-weight: bold;
}

.balance_service>.content .right ._info .right {
    width: 45%;
    float: right;
    height: auto;
    background: #00A550;
    padding-bottom: 45%;
    position: relative;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin-left: 5%;
    border: 2px dashed #fff;
}

.balance_current .right {
    width: 35%;
    float: right;
    height: auto;
    background: #00A550;
    padding-bottom: 25%;
    position: relative;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    margin-left: 5px;
    border: 2px dashed #fff;
}

.balance_current .right .number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 26px;
    color: #fff;
    font-weight: bold;
}

.balance_service .content ._info .right .number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 38px;
    color: #fff;
    font-weight: bold;
}

.balance_service .left .title {
    font-weight: bold;
    margin-top: 10px;
    font-size: 30px;
}

.balance_service .left .des {
    margin-top: 10px;
    font-size: 17px;
}

.list_services {
    width: 100%;
    float: left;
}

.no_list_style {
    list-style: none;
}

.list_services ul {
    float: left;
    width: 100%;
}

.list_services ul li {
    width: 20%;
    float: left;
    padding: 15px;
}

.item_service {
    text-align: center;
    // border-radius: 5px;
    // background: #E7E7E7;
    position: relative;
    // box-shadow: 0px 2px 4px 0px #00000033;
}

.btn-plus {
    position: absolute;
    bottom: 0;
    border-radius: 5px;
    left: 1px;
    right: 1px;
    background: #fff;
    z-index: 1;
    color: #029AFF;
    font-weight: bold;
    height: 35px;
}

.btn-plus a {
    display: block;
    line-height: 25px;
    font-size: 17px;
    padding: 5px;
}

// .item_service:hover {
//     background: #CCD874;
// }

.item_service .icon {
    width: auto;
    height: 90px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 1px dashed #fff;
    background: #00A550;
    color: #fff;
    margin: auto;
    position: relative;
    margin-bottom: 10px;
}

.item_service .icon h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 41px;
}

.item_service .prices h3 {
    font-weight: bold;
    font-size: 30px;
    margin-top: 20px;
}

.item_service .prices p {
    color: #012509;
    font-size: 20px;
}

.item_service .prices a {
    display: block;
    text-align: center;
    color: #fff;
    font-size: 15px;
    margin-top: 15px;
    margin-bottom: 30px;
}

.btn-plus:hover {
    background: #029AFF;
}

.btn-plus:hover a {
    color: #fff;
}

.list_services hr {
    width: calc(100% - 30px);
    height: 1px;
    background: #70707014;
    margin: 0 15px;
    float: left;
}

.support_services {
    margin: 30px 0;
}

.support_services .title {
    font-weight: bold;
    font-size: 25px;
    max-width: 225px;
    margin: auto;
}

.list_qs {
    max-width: 225px;
    margin: auto;
}

.list_qs li {
    margin: 15px 0
}

.list_contact li {
    margin-top: 15px;
}

.list_qs li a {
    text-align: left;
    padding: 15px 15px;
    display: block;
    border: 1px dashed #00A550;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 17px;
    color: #000;
    box-shadow: 0 1px 3px 0px #c1c1c1;
}

.list_contact li {
    position: relative;
    padding: 9px 10px;
    float: left;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0px #c1c1c1;
}

.list_contact li:nth-child(1) {
    background: #00A550;
}

.list_contact li:nth-child(2) {
    background: #FFF000;
}

.list_contact li:nth-child(3) {
    background: #029AFF;
}

.list_contact {
    max-width: 225px;
    margin: auto;
}

.list_contact li a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px dashed #fff;
    color: #fff;
    border-radius: 5px;
}

.item_contact {
    float: left;
    width: 100%;
}

.item_contact .img {
    float: left;
    width: 20%;
    padding: 10px 0;
}

.item_contact .info {
    float: left;
    width: 80%;
}

.item_contact .info p {
    font-size: 17px;
}

.item_contact .info h3 {
    font-size: 19px;
    font-weight: bold;
}

.have-border {
    position: relative;
}

.have-border::after {
    position: absolute;
    left: 0;
    top: 50px;
    bottom: 50px;
    content: '';
    width: 1px;
    background: #000;
    opacity: .3;
}

.name_page {
    width: 150px;
    height: 140px;
    background: #00A550;
    color: #fff;
    padding: 20px 15px 35px 20px;
    position: relative;
    margin-left: 30px;
}

.name_page::after {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 40%;
    height: 1px;
    content: '';
    background: #fff;
}

.name_page h3 {
    font-size: 17px;
    line-height: 1.7;
    font-weight: bold;
    padding-bottom: 10px;
}

.register_partner {
    background: #fff;
    padding: 0 15px;
}

.form_register_partner {
    margin: 5px 165px;
    color: #000
}

.title_form {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 20px;
}

.color_012509 {
    color: #012509;
}

.form_register_partner .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #CCD874;
    background-color: #CCD874;
}

.form_register_partner .custom-control-label::before {
    width: 30px;
    height: 30px;
    border-color: #CCD874;
}

.form_register_partner .custom-control-label::after {
    width: 2rem;
    height: 2rem;
}

.form_register_partner .custom-control-label {
    padding-left: 25px;
    font-weight: bold;
    line-height: 35px;
}

.form_register_partner .custom-control {
    margin-top: 15px;
}

.dashed_707070 {
    border-style: dashed;
    border-color: #707070;
    margin-bottom: 20px;
    border-width: .03rem;
}

.item-info-account {
    margin-bottom: 20px;
    .ant-select-selector {
        height: 100% !important;
        text-align: left;
        background-color: #EDEDED !important;
    }
}

.item_id_account .left {
    padding: 0 50px 0 15px;
    width: 40%;
    float: left;
    font-weight: bold;
}

.item_id_account .right {
    padding: 5px 15px;
    width: calc(60% - 15px);
    float: left;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    background: #120CBD;
    border-radius: 10px;
    color: #fff;
}

.mg-r--15 {
    margin-right: -30px;
}

.btn-light {
    background: #EDEDED;
}

.list_category_job {
    padding: 0 5px;
}

.list_category_job li {
    padding: 10px;
    float: left;
}

.list_category_job li a {
    padding: 7px 15px;
    float: left;
    border-radius: 5px;
    border: 1px solid #BCE0FD;
    font-size: 10px;
    color: #2699FB;
    font-weight: bold;
}

.group_upload_image .upload {
    position: relative;
    display: inline-block;
    text-align: center;
    line-height: 30px;
    float: left;
}

.group_upload_image .upload label {
    color: #5F626A;
    cursor: pointer;
    width: 90px;
    height: 90px;
    position: relative;
    display: block;
    border-radius: 10px;
    overflow: hidden;
    transition: background ease .5s;
    pointer-events: none;
    /*This for FF*/
}

.group_upload_image .input-file {
    opacity: 0;
    /*This*/
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.group_upload_image .add-image {
    display: block;
    width: 100%;
    margin: 30px auto;
    text-align: center;
    font-size: 11px;
    font-family: sans-serif;
}

.group_upload_image .upload .button_select_file {
    border-radius: 5px;
    color: #fff;
    background: #3B86FF;
    font-size: 8px;
    padding: 5px 10px;
}

.list_image_uploaded {
    float: left;
}

.list_image_uploaded li {
    float: left;
    padding: 10px;
    border: 1px dashed #d4d4d4;
    width: 70px;
    height: 70px;
    margin: 0 15px;
    position: relative;
}

.policy_access {
    clear: both;
}

.form_register_partner .policy_access .custom-control-label::before {
    width: 15px;
    height: 15px;
    border-color: #6c757d;
}

.form_register_partner .policy_access .custom-control-label {
    padding-left: 10px;
    line-height: 25px;
    font-weight: 400;
    font-size: 14px;
}

.form_register_partner .policy_access .custom-control-input:checked~.custom-control-label::before {
    background: #6c757d;
    border-color: #6c757d;
}

.form_register_partner .policy_access .custom-control-label::after {
    width: 1rem;
    height: 1rem;
}

.group_btn_submit a {
    padding: 10px 30px;
    margin: 10px 20px;
    color: #fff;
    font-size: 17px;
    line-height: 15px;
    border-radius: 5px;
    min-width: 190px;
    font-weight: bold;
    height: 35px;
}

.group_btn_submit a.back {
    background: #000;
    background: #029AFF;
    color: #fff;
    float: right;
}

.group_btn_submit a.register {
    background: #000;
    background: #029AFF;
    color: #000;
    background: #FFF000;
    float: left;
}

.have_account {
    float: left;
    width: 100%;
    margin-right: 50px;
    font-size: 12px;
}

.have_account a {
    margin-left: 30px;
    font-size: 12px;
}

.have_account.read_policy a {
    color: #232735;
    font-size: 10px;
}

.breadcrumb .content.mg-r-0 {
    margin-right: 0;
}

.dashboard .title_sec {
    background: #00A550;
    color: #fff;
    padding: 15px 30px;
}

.dashboard .content_sec {
    background: #fff;
    width: 100%;
    padding: 30px 0;
}

.item_dashboard_top {
    padding: 20px 30px;
    background: #CCD874;
    width: 310px;
}

.item_dashboard_top h3 {
    font-size: 25px;
    font-weight: bold;
}

.button_under_line {
    margin-top: 10px;
    float: left;
    width: 100%;
}

.button_under_line a.left {
    float: left;
    width: calc(60% - 5px);
    margin-right: 5px;
}

.button_under_line a.right {
    float: right;
    width: 40%;
}

.button_under_line a {
    padding: 5px 10px;
    background: #E7E7E7;
    border-radius: 4px;
    color: #000;
    transition: .15s;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    box-shadow: 0 1px 2px #a0a0a0;
}

.button_under_line a:hover {
    color: #fff;
    background: #029AFF;
}

.upload_plus {
    font-size: 10px;
    line-height: 10px;
    display: block;
}

.support_job.btn {
    position: relative;
    padding-left: 50px;
    line-height: 20px;
    margin-left: 15px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 30px;
}

.support_job.btn i {
    position: absolute;
    left: 0;
    top: 0;
    padding: 5px 20px;
    border-radius: 5px;
    background: #029AFF;
    color: #fff;
    font-size: 20px;
    line-height: 20px;
}

.support_job.btn span {
    padding-left: 10px;
    line-height: 10px;
    color: #029AFF;
    font-size: 14px;
    font-weight: 300;
}

.border-grow button {
    border: 1px solid #cccccc;
    border-radius: 5px;
    background: #fff;
}

.border-grow .left {
    width: 50%;
    float: left;
    margin-top: 0;
}

.border-grow .right {
    width: 50%;
    float: right;
    margin-top: 0;
}

.table_dashboard_new .table {
    border-bottom: 1px solid #B2B2B2;
}

.table_dashboard_new .table thead {
    background: #EDEDED;
}

.table_dashboard_new .user_ {
    color: #029AFF
}

.table_dashboard_new .table thead tr th {
    color: #B2B2B2;
    font-size: 12px;
}

.table_dashboard_new .btn {
    border-radius: 5px;
    font-size: 12px;
    color: #000;
    min-width: 120px;
    padding: 5px 10px;
    line-height: 20px;
    height: 30px;
}

.table_dashboard_new .btn.status_1 {
    background: #00A550;
    color: #fff
}

.table_dashboard_new .btn.status_2 {
    background: #CCD874;
}

.table_dashboard_new .btn.status_3 {
    background: #FF6262;
}

a.btn-show-more {
    color: #3B86FF;
    font-size: 12px;
}

.bs-ok-default.check-mark {
    height: 27px;
    width: 27px;
    background-color: #ffffff;
    border: 1px solid $yellow_2;
    border-radius: 5px;
    display: block !important;
    position: absolute;
    right: 15px;
    &::after {
        position: absolute;
        right: 9px;
        top: 3px;
        color: #fff;
    }
}

li.selected {
    .bs-ok-default.check-mark {
        background-color: $yellow_2;
    }
}

.bootstrap-select .dropdown-menu li a {
    display: block;
    padding-top: 10px;
    padding-bottom: 5px;
}

.select_300 {
    .bootstrap-select {
        width: 300px !important;
        max-width: 100%;
    }
}

.pd-r-30-md {
    padding-right: 30px;
}

.pd-l-30-md {
    padding-left: 30px;
}

.dksd {
    display: block;
    text-align: center;
    width: 100%;
    margin-top: 50px;
    a {
        margin: 0 15px;
        color: $black;
        font-size: 12px;
    }
}

.ant-carousel .slick-prev, .ant-carousel .slick-next {
    background: white !important;
}

.footer-copyright {
    font-size: 12px;
    a {
        font-size: 12px;
    }
}
