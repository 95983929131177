.estimated_history_container {
  margin-top: 120px;
}

.title_sec {
  text-align: left;
}

.btn_show {
  border: none;
  background: none;
  color: #3B86FF;
  margin-top: 35px;
  margin-bottom: 35px;
}