.address_form_input_item {
  max-width: 300px !important;
  height: auto; 
  border-radius: 5px;
  background-color: #EDEDED !important;
}

.form_input_item input {
  background-color: #EDEDED !important;
}

.footer_item {
  display: flex !important;
  justify-content: center;
}

.checkbox_label {
  color: #4D4F5C;
  font-size: 14px;
  font-family: 'DINPro', sans-serif;
  margin-left: 6px;
}

.forget_password_button {
  background: transparent;
  border: none;
  color: #4D4F5C;
  font-size: 14px;
  font-family: 'DINPro', sans-serif; 
}

.modal_login_button {
  width: 21.2vw;
  height: 4.85vh;
  background-color: #00A550;
  border-radius: 5px;
  color: white !important;
  font-weight: bold;
  font-family: 'DINPro', sans-serif; 
  -webkit-box-shadow: 0 0 5px 2px #D3D3D3 !important;
          box-shadow: 0 0 5px 2px #D3D3D3 !important;
}

.address_modal_login_button {
  width: 300px;
  background-color: #00A550;
  border-radius: 5px;
  color: white !important;
  font-weight: bold;
  font-family: 'DINPro', sans-serif; 
  -webkit-box-shadow: 0 0 5px 2px #D3D3D3 !important;
          box-shadow: 0 0 5px 2px #D3D3D3 !important;
}

.modal_register_button {
  background-color: transparent;
  border: none;
  font-weight: bold;
  font-family: 'DINPro', sans-serif; 
}

.form_address_container .ant-row .ant-form-item {
  flex-direction: column;
}
@primary-color: #1DA57A;