@desktop:   ~"(min-width: 960px)";
@mobile:    ~"(min-width: 320px) and (max-width: 768px)";

//HEADER DESKTOP//
#header-wrapper {
  @media @mobile {
    display: none;
  }
  padding: 14px 57px;
  margin-right: 0px !important;
  margin-bottom: 34px;
  #authenticate-wrapper {
    width: 360px;
    float: right;
    align-items: center;
    margin-bottom: 10px;
    p {
      margin-bottom: 0px;
    }
    .auth-btn {
      color: #FFF000;
      font-size: 12px;
      font-weight: bold;
    }
    .language-dropdown-wrapper {
      border: solid 1px #FFFFFF;
      border-radius: 10px;
      color: #FFFFFF;
      font-weight: bold;
      height: 40px;
      padding-top: 8px;
    }
    .language-dropdown-wrapper:hover {
      cursor: pointer;
    }
    .short-name-wrapper {
      align-items: center;
      color: #FFF000;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .notifi-wrapper {
      color: #FFF000;
      font-weight: bold;
      svg {
        height: 20px !important;
        width: 20px !important;
      }
    }
  }
  #navigation-wrapper {
    border-top: solid 1px #00A550;
    border-bottom: solid 1px #00A550;
    height: 72px;
    align-items: center;
    p {
      color: #FFFFFF;
      font-size: 20px;
      margin-bottom: 0px;
      font-weight: bold;
    }
    p:hover {
      background-color: #4EA752;
      border-radius: 16px;
    }
    #home_active, #products_active, #services_active, #contact_active {
      background-color: #4EA752;
      border-radius: 16px;
    }
    #col-logo-wrapper {
      position: relative;
      height: 100%;
      #logo-wrapper {
        margin: auto;
        margin-top: -25px;
        width: 126px;
        height: 126px;
      }
    }
    #search-btn {
      background: none;
      border: none;
      color: #FFFFFF;
      svg {
        height: 24px;
        width: 24px;
      }
    }
  }
}

//HEADER MOBILE//
#mobile-header-wrapper {
  @media @desktop {
    display: none;
  }
  padding: 10px 10px;
  align-items: center;
  margin-right: 0px !important;
  margin-left: 0px !important;
  p {
    margin-bottom: 0px;
  }
  #menu-btn {
    background: none;
    border: none;
    .anticon {
      font-weight: bold !important;
      color: #FFFFFF;
      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
  #search-btn {
    background: none;
    border: none;
    color: #FFFFFF;
    svg {
      height: 20px;
      width: 20px;
    }
  }
  #col-logo-wrapper {
    #logo-wrapper {
      height: 80px;
      width: 80px;
      float: right;
    }
  }
  .language-dropdown-wrapper {
    border: solid 1px #FFFFFF;
    border-radius: 10px;
    color: #FFFFFF;
    font-weight: bold;
    height: 40px;
    padding-top: 8px;
  }
}

//NAVIGATION MOBILE//
.custom-drawer {
  .ant-drawer-wrapper-body {
    background-color: #00A550;
  }
  #mobile-navigation-wrapper {
    .auth-btn {
      color: #FFF000;
      font-size: 12px;
      font-weight: bold;
    }
    .short-name-wrapper {
      align-items: center;
      color: #FFF000;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .notifi-wrapper {
      color: #FFF000;
      font-weight: bold;
      svg {
        height: 20px !important;
        width: 20px !important;
      }
    }
    .ant-avatar {
      border: solid 1px #FFFFFF !important;
    }
    .navigation-item-list {
      margin: auto;
      padding-top: 16px;
      p {
        font-weight: bold;
        margin: auto;
        margin-bottom: 16px;
        color: #FFFFFF;
      }
    }
  }
}

//NOTIFICATION
.container_title {
  background-color: #CCD874;
  color: #000000;
  font-family: 'DINPro', sans-serif;
}

.noti_item {
  background-color: #FFFFFF;
}
@primary-color: #1DA57A;