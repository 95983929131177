@desktop:   ~"(min-width: 960px)";
@mobile:    ~"(min-width: 320px) and (max-width: 768px)";

@media @desktop {
  .content-container {
    background: white;
    margin: 50px 80px;
    padding: 50px 100px;
    color: black;
    div {
      text-align: left;
    }
    table {
      width: 100%;
      margin: auto;
      td {
        border: solid #ccc;
        padding: 5px;
      }
    }
  }
}

@media @mobile {
  .content-container {
    background: white;
    margin: 50px 0px;
    padding: 50px 10px;
    width: 100%;
    color: black;
    div {
      text-align: left;
    }
    table {
      width: 100%;
      margin: auto;
      td {
        border: solid #ccc;
        padding: 5px;
      }
    }
  }
}
@primary-color: #1DA57A;