@desktop:   ~"(min-width: 960px)";
@mobile:    ~"(min-width: 320px) and (max-width: 768px)";

//CAROUSEL

@media @desktop {
  .custom-carousel {
    .slick-prev {
      left: 0px !important;
      width: 40px !important;
      height: 40px !important;
    }
    .slick-next {
      right: 0px !important;
      width: 40px !important;
      height: 40px !important;
    }

    .slick-dots-bottom { 
      bottom: 5px !important;
    }
    .carousel-content-wrapper {
      width: 100%;
      position: relative;
      img {
        width: 100%;
        height: 420px;
      }
      .des-wrapper {
        position: absolute;
        height: 100%;
        width: 347px;
        background-color: rgba(255, 255, 255, 0.5);
        top: 0px;
        padding-top: 146px;
        padding-bottom: 55px;
        h2 {
          color: #012509;
          font-weight: bold;
          font-size: 30px;
        }
        .view-more-btn {
          width: 179px;
          height: 39px;
          border-radius: 25px;
          background-color: #00A550;
          color: #FFFFFF;
          align-items: center;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: auto;
          justify-content: space-around;
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

@media @mobile {
  .custom-carousel {
    .slick-prev {
      left: 0px !important;
      width: 20px !important;
      height: 20px !important;
    }
    .slick-next {
      right: 0px !important;
      width: 20px !important;
      height: 20px !important;
    }

    .slick-dots-bottom { 
      bottom: 5px !important;
    }

    .carousel-content-wrapper {
      width: 100%;
      position: relative;
      img {
        width: 100%;
        height: 280px !important;
      }
      .des-wrapper {
        position: absolute;
        height: 100%;
        width: 220px;
        background-color: rgba(255, 255, 255, 0.5);
        top: 0px;
        padding-top: 116px;
        padding-bottom: 55px;
        h2 {
          color: #012509;
          font-weight: bold;
          font-size: 20px;
        }
        .view-more-btn {
          width: 139px;
          height: 39px;
          border-radius: 20px;
          background-color: #00A550;
          color: #FFFFFF;
          align-items: center;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: auto;
          justify-content: space-around;
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}

@primary-color: #1DA57A;