$green: #00A550;
$yellow:#FFF000;
$yellow_2:#CCD874;
$yellow_3: #FFC400;
$blue: #029AFF;
$blue_2: #F1F9FF;
$gray: #D3D3D3;
$gray_2:#f9f9f9;
$gray_3:#EDEDED;
$gray_text: #707070;
$black: #000000;
$white: #ffffff;
$bg: #012509;
$red: #D60000;
// End Variable
@media (min-width: 576px) {
    .container,
    .container-sm {
        max-width: calc(100% - 30px);
    }
}

@media (min-width: 768px) {
    .container,
    .container-md,
    .container-sm {
        max-width: 95vw;
    }
}

@media (min-width: 1300px) {
    .container {
        max-width: 1260px;
    }
}

@media (max-width: 991px) {
    .list_product {
        grid-template-columns: repeat(4, 1fr);
    }
    .item_product {
        height: auto;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }
    .home_slider ._item {
        img {
            width: 100%;
            min-height: 230px;
            object-fit: cover;
        }
        ._content {
            width: 300px;
            height: 100%;
            background: #f9f9f9b0;
            padding: 15px;
            h2 {
                font-size: 22px;
            }
            a {
                margin-top: 15px;
                font-size: 14px;
            }
        }
    }
    .row_news_3 {
        grid-gap: 10px;
        ._item .text {
            font-size: 16px;
        }
    }
    .mw_1048 {
        max-width: 100%;
        width: auto;
    }
    .modal_login {
        display: block;
        display: block;
        width: auto;
        margin: 0 auto;
        .left {
            .login_form {
                margin: 0 30px;
                padding: 30px 0;
            }
        }
        .right {
            display: none;
        }
    }
    form.border-green .dropdown-toggle,
    form.border-green .mw-330 {
        max-width: 100%;
    }
    .form_contact .col input {
        width: 100%;
    }
    .table_2>div:first-child {
        width: 115px;
    }
    .box_product_post .flex_4_pr {
        display: block;
        margin: 0 -15px;
        .form-group {
            display: inline-block !important;
            width: 50%;
            min-width: 50%;
            float: left;
            padding-right: 15px;
            padding-left: 15px;
        }
    }
    .border-green .dropdown-toggle {
        max-width: 100%;
    }
    .list_services ul li {
        width: 33.33%;
    }
    .balance_service>.content .left {
        width: 66.66%;
    }
    .balance_service>.content .right {
        width: 33.33%;
    }
    .balance_service>.content .right ._info {
        padding: 10px 15px;
    }
    .balance_service .left .title {
        font-size: 18px;
    }
    .balance_service .left .des {
        font-size: 14px;
    }
    .name_page {
        padding: 10px 15px 10px 20px;
        width: 100%;
        height: auto;
        margin-left: 0;
    }
    .form_register_partner {
        margin: 10px 0;
    }
    .item_id_account .left {
        padding: 0 15px
    }
}

// End 991
@media (max-width: 768px) {
    .container_header {
        width: 100%;
        min-width: 100%;
    }
    .top_bar {
        display: none;
    }
    .show_sm {
        display: block;
    }
    .header_main {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 80px;
        overflow: hidden;
        transition: all 0.3s;
        &.active {
            height: 410px;
        }
        ._logo {
            order: -1;
            max-height: 80px;
            img {
                max-height: 76px;
                margin: 2px;
            }
        }
        ._menu {
            display: block;
            width: 100%;
            border: none;
            padding: 0 15px;
            position: static;
            &._left {
                background: none;
                padding-top: 20px;
            }
            &._right {
                background: none;
                padding-bottom: 40px;
            }
            a {
                display: block;
                margin-top: 15px;
            }
        }
        .top_login {
            display: flex;
            text-align: center;
            justify-content: space-evenly;
            align-items: baseline;
            border-top: 1px solid;
            margin-top: 15px;
        }
        .search_icon {
            right: 15px;
            top: 26px;
        }
        .trigger_menu {
            display: inline-block;
            position: absolute;
            left: 15px;
            top: 25px;
            width: 30px;
            height: 30px;
            background-image: url(./assets/icon/menu.png);
            background-repeat: no-repeat;
            background-size: contain;
            cursor: pointer;
            &.active {
                background-image: url(./assets/icon/close.png);
            }
        }
    }
    .list_product {
        grid-template-columns: repeat(3, 1fr);
    }
    .col_filter_product {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 15px;
        .dropdown-toggle,
        .dropdown-toggle:focus {
            padding: 0 15px;
            height: 39px;
            font-size: 14px;
            width: 100%;
        }
        .filter_product {
            grid-column-start: span 2;
            ._col {
                padding: 0;
                padding-right: 5px;
            }
            ._col:last-child {
                padding-right: 0;
            }
            .btn {
                width: 100%;
                font-size: 14px;
                min-width: 92px;
            }
        }
        .search {
            grid-column: 2;
            grid-row: 1;
            a {
                width: 100%;
            }
        }
    }
    .footer_main {
        .row_top {
            display: grid;
            grid-template-columns: 25% 25% 25% 25%;
            .col:first-child {
                grid-column: 1;
                grid-row: 1;
                grid-column-start: 1;
                grid-column-end: span 4;
            }
            .col {
                margin-top: 25px;
                padding-left: 0;
            }
        }
        .row_copyright {
            .col-xs-12 {
                text-align: center !important;
                margin-top: 15px;
                ._title {
                    display: none;
                }
            }
            .right {
                a {
                    margin: 0 10px;
                }
            }
        }
    }
    .row_news_3 {
        display: block;
        ._item {
            margin: 15px 0;
            max-height: 300px;
            overflow: hidden;
            ._text {
                font-size: 24px;
                height: 100%;
                padding: 20px;
                background: #0125099c;
            }
        }
    }
    .btn.small,
    .btn:focus.small {
        padding: 5px 8px;
    }
    .breadcrumb {
        padding: 0 !important;
        display: block;
        margin: 15px auto;
        .content {
            display: none;
        }
    }
    .row_checkout_2 {
        display: block;
        width: 100%;
        margin: 0;
        >.col-6 {
            display: block;
            width: 100%;
            max-width: 100%;
            clear: both;
        }
    }
    .p_2_col {
        .right .title {
            height: auto;
            margin-bottom: 10px;
        }
    }
    .single_service {
        >.content {
            padding: 15px 0;
            .right {
                width: 180px;
            }
        }
    }
    .partner_info {
        max-width: 100%;
        display: block;
    }
    .partner_quote {
        .row {
            padding: 0 10px;
            .col-2 {
                max-width: 100px;
                width: 100px;
                flex: 0 0 100px;
                padding-right: 0;
                position: absolute;
                right: 0;
                padding: 0;
            }
            &.top .col {
                margin-left: -30px;
                margin: 0;
                padding: 0;
                max-width: 70%;
            }
        }
        .career_box {
            font-size: 13px;
            .item {
                height: auto;
                line-height: 22px;
                padding: 0 5px;
                margin-left: 10px;
                border-radius: 5px;
                font-size: 13px;
                margin: 3px 0;
            }
        }
    }
    .flex_5 .col {
        padding: 0 5px;
        display: inline-block;
        width: auto;
        padding: 5px;
    }
    .col.pd-lr-30 {
        padding-left: 15px;
        padding-right: 15px;
    }
    .text-center-sm {
        text-align: center;
    }
    .box_feedback {
        width: 100%;
    }
    .info_supplier .row {
        display: flex;
        .col {
            width: 50%;
        }
    }
    .table_dashboard_new {
        width: 100%;
        overflow: auto;
    }
    .table_dashboard_new .table {
        width: 600px;
    }
    .mobile_padding {
        padding: 0 15px;
    }
    .balance_current {
        width: 100%;
        margin: auto;
    }
    .button_under_line {
        width: calc(100% - 60px);
        margin: 10px auto;
    }
    .item_dashboard_top {
        margin-bottom: 10px;
        width: 100%;
        padding: 10px;
    }
    .item_dashboard_top h3 {
        font-size: 16px;
    }
    .border-grow .left,
    .border-grow .right {
        width: 100%;
        margin-bottom: 10px;
    }
    .balance_current .right {
        width: 30%;
        padding-bottom: 20px;
    }
    .form_left>.pd-r-30 {
        padding-left: 30px;
    }
    .form_left .pd-l-30 {
        padding-right: 30px;
    }
    .balance_service>.content .left {
        width: 60%;
    }
    .balance_service>.content .right {
        width: 40%;
    }
    .balance_service>.content .right ._info .left {
        font-size: 14px;
        padding: 5px;
    }
    .support_services>div {
        margin-bottom: 20px;
        text-align: center;
    }
    .have-border::after {
        display: none;
    }
    .item_id_account .right h3 {
        font-size: 17px;
        line-height: 30px;
    }
    .register_partner .pd-l-30,
    .register_partner .pd-r-30 {
        padding: 0 15px
    }
    .register_partner .mg-r--15 {
        margin-right: 0;
    }
    .register_partner .item-info-account.mg-r-15 {
        margin-right: 0;
    }
    .register_partner .item-info-account.mg-l-15 {
        margin-left: 0;
    }
    .list_image_uploaded li {
        margin-bottom: 10px;
    }
    .dksd {
        padding-top: 0;
        clear: both;
    }
    .have_account {
        padding-top: 15px;
    }
}

// end 768
@media (max-width: 640px) {
    .row {
        display: block;
    }
    .container {
        padding-right: 25px;
        padding-left: 25px;
    }
    .container_header {
        padding: 0;
    }
    .list_product {
        grid-template-columns: repeat(1, 1fr);
    }
    .filter_product label {
        font-size: 12px;
    }
    .item_product {
        ._content {
            ._price {
                font-size: 16px;
                ._old {
                    font-size: 11px;
                    padding-left: 2px;
                }
            }
            .btn {
                padding: 6px;
            }
        }
    }
    .btn {
        padding: 7px 10px;
    }
    .category_pagination {
        display: block;
        margin-bottom: 50px;
        text-align: center;
        margin-top: 20px;
        >li {
            display: inline-block;
            float: none;
            text-align: center;
            margin-top: 15px;
        }
    }
    .info_supplier .row {
        display: block;
        .col {
            width: 100%;
            input {
                width: 100%;
            }
        }
    }
    .footer_main {
        .row_top {
            grid-template-columns: 50% 50%;
            .col:first-child {
                grid-column-end: span 2;
            }
        }
    }
    .row_flex_2 {
        display: block;
        >.right {
            margin: 25px 0;
        }
    }
    .form_shipping ._box {
        max-width: 100%;
        padding-left: 0;
    }
    .single_service {
        >.content {
            display: block;
            .right {
                width: 100%;
                padding-left: 0;
                ._info {
                    display: block;
                    width: 100%;
                    border-top: 1px solid $gray;
                    padding-top: 15px;
                    margin-top: 15px;
                    >div {
                        display: inline-block;
                        float: left;
                        font-size: 12px;
                        margin-right: 15px;
                        padding-left: 25px;
                    }
                }
            }
        }
        .row {
            clear: both;
        }
    }
    .single_service {
        .category,
        .category * {
            font-size: 13px;
        }
    }
    .notifi {
        padding-top: 15px;
        &.err::before {
            top: 15px;
        }
    }
    .product_sg_content.cart_content {
        padding: 0;
    }
    .p_2_col .left {
        display: none;
    }
    .cart_product tr {
        th:nth-child(2),
        td:nth-child(2) {
            display: none;
        }
    }
    .group_btn_submit .full_width_mobile {
        width: 50% !important;
    }
    .group_btn_submit a {
        min-width: 100px;
        padding: 10px;
        font-size: 14px;
    }
    .balance_service>.content .left {
        width: 100%;
    }
    .balance_service>.content .right {
        width: 100%;
        margin-top: 10px;
    }
    .list_services ul li {
        width: 100%;
    }
    .balance_service>.content .right ._info .right {
        width: 35%;
        padding-bottom: 35%;
        margin-top: 0;
    }
    .balance_service>.content .right ._info .left {
        padding: 25px 0;
    }
    .list_qs,
    .list_contact {
        max-width: 100%;
        text-align: left;
    }
    .group_btn_submit.row {
        display: flex;
    }
    .have_account {
        margin: 0;
        a {
            margin: 0 15px;
            padding-bottom: 15px;
        }
    }
    .register_partner {
        padding-bottom: 15px;
    }
}