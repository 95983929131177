@desktop:   ~"(min-width: 768px)";
@mobile:    ~"(min-width: 320px) and (max-width: 767px)";

@media @desktop {
  //SERVICE CARD COMPONENT//
  //--------------------------//
  .service-card-wrapper {
    background-color: #FFFFFF;
    width: 611px;
    height: 327px;
    margin: auto !important;
    // margin-left: 23px !important;
    margin-bottom: 42px !important;
    .title-wrapper {
      background-color: #00A550;
      height: 39px !important;
      padding-top: 8px;
      padding-bottom: 8px;
      text-align: left;
      display: flex;
      align-items: center;
      h3 {
        color: #FFFFFF;
        font-size: 13px;
        font-weight: bold;
        margin-bottom: 0px;
        text-transform: uppercase;
      }
    }

    .text-des-wrapper {
      height: 140px;
      width: 100%;
      text-align: left;
      overflow: hidden;
      margin-left: 15px;
    }

    .btn-control-wrapper {
      height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-right: 15px;
      margin: auto;
      .ant-btn {
        width: 155px;
        height: 34px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: bold;
      }
      .btn-top {
        border: solid 1px #CCD874;
        color: #CCD874;
      }
      .btn-bottom {
        border: solid 1px #00A550;
        color: #00A550;
      }
      .btn-top:hover {
          background-color: #CCD874;
          color: #012509;
      }
      .btn-bottom:hover {
        background-color: #00A550;
        color: #FFFFFF;
      }
    }

    .bonus-info-wrapper {
      .info-item {
        display: flex;
        align-items: center;
        color: black !important;
        p {
          margin-bottom: 0px;
          margin-left: 9px;
        }
      }
    }

    .category-wrapper {
      display: flex;
      align-items: center;
      h5 {
        font-size: 14px;
        color: #012509;
        margin-bottom: 0px;
      }
      .category-item-wrapper {
        p {
          margin-bottom: 0px;
          margin-left: 12px;
          color: #029AFF;
        }
      }
    }
  }

  .services-wrapper {
    padding: 0 24px;
    margin: auto;
  }
}

@media @mobile {
  //SERVICE CARD COMPONENT//
  //--------------------------//
  .service-card-wrapper {
    background-color: #FFFFFF;
    max-width: 611px;
    width: 96.875vw;
    height: 347px;
    margin: auto !important;
    margin-top: 14px !important;
    .title-wrapper {
      background-color: #00A550;
      height: 39px !important;
      padding-top: 4px;
      padding-bottom: 4px;
      text-align: left;
      display: flex;
      align-items: center;
      h3 {
        color: #FFFFFF;
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 0px;
        text-transform: uppercase;
      }
    }

    .text-des-wrapper {
      height: 120px;
      text-align: left;
      overflow: hidden;
      margin-left: 15px;
      color: black !important;
    }

    .btn-control-wrapper {
      height: 70px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-right: 15px;
      .ant-btn {
        width: 155px;
        height: 30px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: bold;
      }
      .btn-top {
        border: solid 1px #CCD874;
        color: #CCD874;
      }
      .btn-bottom {
        border: solid 1px #00A550;
        color: #00A550;
      }
      .btn-top:hover {
          background-color: #CCD874;
          color: #012509;
      }
      .btn-bottom:hover {
        background-color: #00A550;
        color: #FFFFFF;
      }
    }

    .bonus-info-wrapper {
      .info-item {
        display: flex;
        align-items: center;
        color: black !important;
        p {
          margin-bottom: 0px;
          margin-left: 9px;
        }
      }
    }

    .category-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      h5 {
        font-size: 14px;
        color: #012509;
        margin-bottom: 0px;
      }
      .category-item-wrapper {
        p {
          margin-bottom: 0px;
          margin-left: 12px;
          color: #029AFF;
        }
      }
    }
  }
}
@primary-color: #1DA57A;