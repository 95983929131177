.backdrop_title {
  width: 16.105vw;
  color: #012509;
  font-family: 'DINPro' sans-serif;
  font-size: 17px;
  margin-left: 0.878vw;
}

.estimate_info_container {
  width: 52.562vw;
  height: 319px;
  margin-top: 44px;
  margin-left: 5.051vw;
}

.service_name_label {
  color: #A1A0AE;
  width: 5.271vw;
}

.service_name {
  width: auto;
  color: #012509;
  font-size: 14px;
  font-family: 'DINPro' sans-serif;
  padding-bottom: 42.5px;
}

.info_item {
  width: 47.291vw;
  border-bottom: 2px #707070 solid;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.btn_view_detail {
  position: absolute;
  top: 70px;
  right: 2.635vw;
}

.item_label {
  color: #A1A0AE;
  width: 5.271vw;
  margin-top: 26px;
}

.budget_container {
  display: flex;
  flex-direction: row;
  width: 17.423vw;
  height: 93px;
  justify-content: space-around;
  align-items: center;
  margin-left: 5.857vw;
}

.budget_item {
  padding: 12px 31px 7px 32px;
  border: solid #012509 1px;
  border-radius: 5px;
  color: #2699FB;
  font-weight: bold;
  font-size: 17px;
  font-family: 'DINPro' sans-serif;
}

.budget_item_select {
  width: 5.417vw !important;
  border-radius: 5px;
  margin-bottom: 18px;
}

.service_fee_container {
  display: flex;
  flex-direction: row;
  width: 7.76vw;
  height: 93px;
  justify-content: space-around;
  align-items: center;
}

.service_fee_box {
  padding: 8px 26px 2px 27px;
  border: 1px dashed #FFF000;
}

.coin_infor_container {
  width: 27.672vw;
  height: 355px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.coin_infor_header {
  padding: 8px 30px 4px 36px;
  border: solid #FFF000 1px;
  background-color: #FFF000;
}

.coin_amount {
  width: 90px;
  height: 90px;
  border-radius: 45px;
  background-color: #00A550;
  border: 1px dashed white;
  color: white !important;
  font-size: 50px;
}

.coint_container_footer {
  display: flex;
  justify-content: space-around;
}

.btn_send_estimate {
  width: 15.007vw;
  height: 41px;
  background-color: #F9F9F9;
  border-radius: 5px;
  color: black;
  font-weight: bold;
}

.btn_add_coin {
  width: 12.299vw;
  height: 41px;
  background-color: #029AFF;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  margin-left: 1.025vw;
}

