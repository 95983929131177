@desktop:   ~"(min-width: 960px)";
@mobile:    ~"(min-width: 320px) and (max-width: 768px)";

::-webkit-scrollbar {
  height: 5px;
}


@media @desktop {
  //CUSTOMER PROFILE
  //----------------------//
  #customer-profile-wrapper {
    padding-left: 57px;
    padding-right: 57px;
    max-width: 1326px;
    margin: auto !important;
    #title-wrapper {
      background-color: #00A550;
      font-weight: bold;
      font-size: 17px;
      color: #FFFFFF;
      text-align: left;
      padding-left: 17px;
      padding-top: 22px;
      padding-bottom: 22px;
    }
    #profile-header-wrapper {
      background-color: #FFFFFF;
      display: flex;
      flex-direction: row;
      padding: 34px 226px 109px 138px;
      justify-content: space-between;
      #profile-account-type-wrapper {
        height: 111px;
        width: 277px;
        background-color: #CCD874;
        color: #012509;
        padding-top: 27px;
        padding-bottom: 30px;
        h4 {
          margin-bottom: 9px;
        }
        h3 {
          font-weight: bold;
        }
      }
      #provider-wrapper {
        height: 111px;
        width: 277px;
        background-color: #CCD874;
        color: #012509;
        padding-top: 27px;
        padding-bottom: 30px;
        margin-left: 24px;
        margin-right: 24px;
        h4 {
          margin-bottom: 9px;
        }
        h3 {
          font-weight: bold;
        }
      }
      #profile-coin-wrapper {
        width: 276px;
        #coin-info-wrapper {
          height: 111px;
          background-color: #FFF000;
          padding: 34px 27px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          h4 {
            width: 124px;
            font-size: 14px;
          }
          p {
            color: #00A550;
            font-size: 30px;
            font-weight: bold;
          }
        }
        #coin-control-button-wrapper {
          margin-top: 10px;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          #add-coin-btn {
            height: 40px;
            width: 149px;
            border-radius: 6px;
            background-color: #029AFF;
            color: #FFFFFF;
            font-weight: bold;
            font-size: 17px;
          }
          #history-coin-btn {
            height: 40px;
            width: 137px;
            border-radius: 6px;
            background-color: #E7E7E7;
            color: #012509;
            font-weight: bold;
            font-size: 17px;
          }
        }
      }
    }
    #profile-form-wrapper {
      background-color: #FFFFFF;
      padding-left: 105px;
      padding-right: 226px;
      #profile-title-wrapper {
        display: flex;
        flex-direction: row;
        width: 360px;
        justify-content: space-between;
        align-items: center;
        h4 {
          font-size: 17px;
          margin-bottom: 0px;
        }
        .ant-btn {
          background-color: #CCD874;
          border-radius: 10px;
        }
      }

      .profile-form {
        margin-top: 30px;
        #shortname-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          width: 50%;
          #shortname-label {
            color: #000000;
            font-size: 14px;
            font-weight: bold;
            padding-top: 4px;
          }
          #shortname {
            width: 197px;
            height: 38px;
            background-color: #120CBD;
            border-radius: 5px;
            color: #FFFFFF;
            font-weight: bold;
            font-size: 20px;
            padding-top: 4px;
          }
        }
        .ant-form-item {
          .ant-form-item-control {
            .ant-form-item-control-input {
              .ant-form-item-control-input-content {
                input {
                  background: #EDEDED;
                  border-radius: 5px;
                }
                .ant-select-show-arrow {
                  .ant-select-selector {
                    background: #EDEDED;
                    border-radius: 5px;
                  }
                }
              }
            }
          }
          #info-form-submit-btn {
            background-color: #00A550;
            color: #FFFFFF;
            font-weight: bold;
            border-radius: 5px;
            float: left;
          }
        }
      }
    }

    #company-form-wrapper {
      background-color: #FFFFFF;
      padding-left: 105px;
      padding-right: 226px;
      padding-top: 39px;
      #company-title-wrapper {
        display: flex;
        flex-direction: row;
        width: 360px;
        justify-content: space-between;
        align-items: center;
        h4 {
          font-size: 17px;
          margin-bottom: 0px;
        }
        .ant-btn {
          background-color: #CCD874;
          border-radius: 10px;
        }
      }
      .company-form {
        margin-top: 30px;
        .ant-form-item {
          .ant-form-item-control {
            .ant-form-item-control-input {
              .ant-form-item-control-input-content {
                input {
                  background: #EDEDED;
                  border-radius: 5px;
                }
                .ant-select-show-arrow {
                  .ant-select-selector {
                    background: #EDEDED;
                    border-radius: 5px;
                  }
                }
              }
            }
          }
          #company-form-submit-btn {
            float: left;
            background-color: #00A550;
            color: #FFFFFF;
            font-weight: bold;
            border-radius: 5px;
          }
        }
      }
    }

    #bussiness-information-wrapper {
      background-color: #FFFFFF;
      padding-top: 35px;
      padding-left: 105px;
      text-align: left;
      margin-left: 0px !important;
      margin-right: 0px !important;
      .ant-col {
        padding: 0px !important;
      }
      #bussines-list-wrapper {
        #bussines-list-item-wrapper {
          display: flex;
          flex-direction: row;
          width: 500px;
          height: 30px;
          overflow-x: scroll;
        }
      }
      #update-bussiness-wrapper {
        #update-btn-control-wrapper {
          display: flex;
          flex: row;
          justify-content: space-between;
          align-items: center;
          width: 221px;
          margin-bottom: 10px;
          p {
            color: #029AFF;
            margin-bottom: 0px;
          }
          .ant-btn {
            margin-top: 0px;
            width: 49px;
            height: 32px;
            border-radius: 10px;
            background-color: #029AFF;
            color: #FFFFFF;
            text-align: center;
          }
        }
        #update-selector-wrapper {
          display: flex;
          flex-direction: row;
          .ant-select {
            margin-right: 12px;
            .ant-select-selector {
              width: 262px;
            }
          }
        }
        .ant-btn {
          margin-top: 16px;
          background-color: #00A550;
          color: #FFFFFF;
          font-weight: bold;
        }
      }
    }
  }
}

@media @mobile {
  //CUSTOMER PROFILE
  //----------------------//
  #customer-profile-wrapper {
    padding-left: 12px;
    padding-right: 12px;
    #title-wrapper {
      background-color: #00A550;
      font-weight: bold;
      font-size: 17px;
      color: #FFFFFF;
      text-align: left;
      padding-left: 17px;
      padding-top: 22px;
      padding-bottom: 22px;
    }
    #profile-header-wrapper {
      background-color: #FFFFFF;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 14px 10px;
      #profile-account-type-wrapper {
        height: 111px;
        width: 277px;
        background-color: #CCD874;
        color: #012509;
        padding-top: 27px;
        padding-bottom: 30px;
        margin-bottom: 16px;
        h4 {
          margin-bottom: 9px;
        }
        h3 {
          font-weight: bold;
        }
      }
      #profile-coin-wrapper {
        width: 276px;
        #coin-info-wrapper {
          height: 111px;
          background-color: #FFF000;
          padding: 34px 27px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          h4 {
            width: 124px;
            font-size: 14px;
          }
          p {
            color: #00A550;
            font-size: 30px;
            font-weight: bold;
          }
        }
        #coin-control-button-wrapper {
          margin-top: 10px;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          #add-coin-btn {
            height: 40px;
            width: 149px;
            border-radius: 6px;
            background-color: #029AFF;
            color: #FFFFFF;
            font-weight: bold;
            font-size: 17px;
          }
          #history-coin-btn {
            height: 40px;
            width: 137px;
            border-radius: 6px;
            background-color: #E7E7E7;
            color: #012509;
            font-weight: bold;
            font-size: 17px;
          }
        }
      }
    }
    #profile-form-wrapper {
      background-color: #FFFFFF;
      padding-left: 12px;
      padding-right: 12px;
      #profile-title-wrapper {
        display: flex;
        flex-direction: row;
        width: 360px;
        justify-content: space-between;
        align-items: center;
        h4 {
          font-size: 17px;
          margin-bottom: 0px;
        }
        .ant-btn {
          background-color: #CCD874;
          border-radius: 10px;
        }
      }

      .profile-form {
        margin-top: 30px;
        #shortname-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          width: 100%;
          max-width: 460px;
          #shortname-label {
            color: #000000;
            font-size: 14px;
            font-weight: bold;
            padding-top: 4px;
          }
          #shortname {
            width: 197px;
            height: 38px;
            background-color: #120CBD;
            border-radius: 5px;
            color: #FFFFFF;
            font-weight: bold;
            font-size: 20px;
            padding-top: 4px;
          }
        }
        .ant-form-item {
          .ant-form-item-control {
            .ant-form-item-control-input {
              .ant-form-item-control-input-content {
                input {
                  background: #EDEDED;
                  border-radius: 5px;
                }
                .ant-select-show-arrow {
                  .ant-select-selector {
                    background: #EDEDED;
                    border-radius: 5px;
                  }
                }
              }
            }
          }
          #info-form-submit-btn {
            background-color: #00A550;
            color: #FFFFFF;
            font-weight: bold;
            border-radius: 5px;
            float: left;
          }
        }
      }
    }

    #company-form-wrapper {
      background-color: #FFFFFF;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 39px;
      #company-title-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: 460px;
        justify-content: space-between;
        align-items: center;
        h4 {
          font-size: 17px;
          margin-bottom: 0px;
        }
        .ant-btn {
          background-color: #CCD874;
          border-radius: 10px;
        }
      }
      .company-form {
        margin-top: 30px;
        .ant-form-item {
          .ant-form-item-control {
            .ant-form-item-control-input {
              .ant-form-item-control-input-content {
                input {
                  background: #EDEDED;
                  border-radius: 5px;
                }
                .ant-select-show-arrow {
                  .ant-select-selector {
                    background: #EDEDED;
                    border-radius: 5px;
                  }
                }
              }
            }
          }
          #company-form-submit-btn {
            float: left;
            background-color: #00A550;
            color: #FFFFFF;
            font-weight: bold;
            border-radius: 5px;
          }
        }
      }
    }

    #bussiness-information-wrapper {
      background-color: #FFFFFF;
      padding-top: 35px;
      padding-left: 10px;
      text-align: left;
      margin-left: 0px !important;
      margin-right: 0px !important;
      .ant-col {
        padding: 0px !important;
      }
      #bussines-list-wrapper {
        #bussines-list-item-wrapper {
          display: flex;
          flex-direction: row;
          width: 360px;
          height: 30px;
          overflow-x: scroll;
          .ant-select {
            width: 160px;
            .ant-select-selector {
              width: 100%;
            }
          }
        }
      }
      #update-bussiness-wrapper {
        padding-top: 16px;
        margin-bottom: 16px;
        #update-btn-control-wrapper {
          display: flex;
          flex: row;
          justify-content: space-between;
          align-items: center;
          width: 221px;
          margin-bottom: 10px;
          p {
            color: #029AFF;
            margin-bottom: 0px;
          }
          .ant-btn {
            margin-top: 0px;
            width: 49px;
            height: 32px;
            border-radius: 10px;
            background-color: #029AFF;
            color: #FFFFFF;
            text-align: center;
          }
        }
        #update-selector-wrapper {
          display: flex;
          .ant-select {
            margin-right: 12px;
            .ant-select-selector {
              width: 180px;
            }
          }
        }
        .ant-btn {
          margin-top: 16px;
          background-color: #00A550;
          color: #FFFFFF;
          font-weight: bold;
        }
      }
    }
  }
}
@primary-color: #1DA57A;