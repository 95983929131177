@desktop:   ~"(min-width: 960px)";
@mobile:    ~"(min-width: 320px) and (max-width: 768px)";

@media @desktop {
  #layout-content-container {
    background: white;
    margin: 50px 80px;
    padding: 50px 100px;
    text-align: left;
  
    #title {
      text-align: center;
      margin-bottom: 20px;
    }
  }
}

@media @mobile {
  #layout-content-container {
    background: white;
    margin: 50px 0px;
    padding: 50px 10px;
    text-align: left;
    width: 100%;
  
    #title {
      text-align: center;
      margin-bottom: 20px;
    }
  }
}
@primary-color: #1DA57A;