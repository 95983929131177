@desktop:   ~"(min-width: 960px)";
@mobile:    ~"(min-width: 320px) and (max-width: 768px)";

@media @desktop {
  #new-form-product-wrapper {
    background-color: #FFFFFF;
    max-width: 1253px;
    margin: auto !important;
    margin-top: 30px !important;
    margin-bottom: 24px !important;
    padding: 10px 99px;
    #title-wrapper {
      background-color: #00A550;
      h1 {
        color: #FFFFFF;
        font-size: 17px;
        text-align: left;
      }
    }
    .custom-post-product-form {
      margin-top: 27px;
      .custom-text-area {
        height: 150px;
      }
      .ant-form-item {
        .ant-form-item-control-input {
          // border: solid 0.5px #00A550;
          .ant-form-item-control-input-content {
            // border: solid 0.5px #00A550;
            .ant-input {
              border: solid 0.5px #00A550;
              box-shadow: 0 4px 4px #BABABA;
            }
            .ant-select-selector {
              border: solid 0.5px #00A550;
              box-shadow: 0 4px 4px #BABABA;
            }
          }
        }
      }
      .ant-checkbox {
        border: solid 2px #CCD874;
        width: 30px;
        height: 30px;
        border-radius: 6px;
        .ant-checkbox-input {
          width: 100%;
          height: 100%;
        }
        .ant-checkbox-inner {
          width: 100%;
          height: 100%;
        }
      }
    }

    #post-product-btn {
      background-color: #00A550;
      width: 229px;
      height: 50px;
      color: #000000;
      font-weight: bold;
      font-size: 17px;
    }
  }
}

@media @mobile {
  #new-form-product-wrapper {
    background-color: #FFFFFF;
    max-width: 1253px;
    width: 100%;
    margin: auto !important;
    margin-top: 30px !important;
    margin-bottom: 24px !important;
    padding: 10px 0px;
    #title-wrapper {
      background-color: #00A550;
      h1 {
        color: #FFFFFF;
        font-size: 17px;
        text-align: left;
      }
    }
    .custom-post-product-form {
      margin-top: 27px;
      .custom-text-area {
        height: 150px;
      }
      .ant-form-item {
        .ant-form-item-control-input {
          // border: solid 0.5px #00A550;
          .ant-form-item-control-input-content {
            // border: solid 0.5px #00A550;
            .ant-input {
              border: solid 0.5px #00A550;
              box-shadow: 0 4px 4px #BABABA;
            }
            .ant-select-selector {
              border: solid 0.5px #00A550;
              box-shadow: 0 4px 4px #BABABA;
            }
          }
        }
      }
      .ant-checkbox {
        border: solid 2px #CCD874;
        width: 30px;
        height: 30px;
        border-radius: 6px;
        .ant-checkbox-input {
          width: 100%;
          height: 100%;
        }
        .ant-checkbox-inner {
          width: 100%;
          height: 100%;
        }
      }
      #post-product-btn {
        background-color: #00A550;
        width: 229px;
        height: 50px;
        color: #000000;
        font-weight: bold;
        font-size: 17px;
      }
    }
  }
}
@primary-color: #1DA57A;