.heading_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 240px;
}

.heading_container h3 {
  font-size: 17px;
  font-family: 'DINPro', sans-serif;
  font-weight: bold;
  float: left;
}

.heading_container p {
  font-size: 14px;
  font-family: 'DINPro', sans-serif;
  float: left;
}