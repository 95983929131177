// CodeBase
//----------------------------//
.flex_start_col_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

//----------------------------//

//Custom elemen
//----------------------------//
h4 {
  font-family: 'DINPro', sans-serif;
  color: #012509;
  font-weight: bold;
  margin-bottom: 36px;
  font-size: 14px;
}
//---------------------------//

.body_header {
  margin-top: 34px;
  margin-bottom: 24px;
}

.body_header p {
  text-align: left;
}

.body_main {
  height: auto;
}

.main__footer {
  width: 100%;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 70px;
}

.footer_row {
  width: 100%;
  height: 28px;
  display: flex;
  flex-direction: row;
}

.footer_row p {
  font-size: 12px;
  margin: auto;
  margin-left: 10px;
  padding: 0;
}

.map_container {
  margin-top: 36px;
}

.contact_body_container {
  p {
    color: #000000;
  }
}
