.modal_container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 298px;
  justify-content: space-around;
}

.modal_container_header {
  width: 86px;
  height: 86px;
}

.container_body {
  text-align: center;
  width: 428px;
}

.container_footer {
  width: 352px;
  display: flex;
  justify-content: space-between;
}

.modal_button {
  width: 168px;
  height: 40px;
  border: none;
  border-radius: 6px;
  text-align: center;
  font-family: 'DINPro', sans-serif;
  font-size: 14px;
  font-weight: bold;
}

.pay_btn {
  background-color: #00A550;
  color: #012509;
}

.check_wallet_btn {
  background-color: #029AFF;
  color: #FFFFFF;
}