.checkbox_container {
  display: block;
  position: relative;
  width: 124px;
  height: 30px;
  cursor: pointer;
  user-select: none;
}

.checkbox_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: white;
  border: solid #CCD874 2px;
}

.checkbox_container input:checked ~ .checkmarK {
  background-color: #CCD874;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox_container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox_container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.contact_footer_container {
  margin-top: 36px;
}

.form_contact_container {
  width: 778px;
}

.green_btn {
  margin-top: 46px;
  margin-bottom: 74px;
  width: 202px;
  height: 50px;
  border-radius: 2px;
  background-color: #00A550;
  color: white;
  font-weight: bold;
  font-family: 'DINPro', sans-serif;
  border: none;
}
