@desktop:   ~"(min-width: 960px)";
@mobile:    ~"(min-width: 320px) and (max-width: 768px)";


@media @desktop {
  .cart_content {
    margin: auto;
  }
  .p_2_col {
    .right {
      .right_utility {
        display: none;
      }
    }
  }
}

@media @mobile {
  .p_2_col {
    .right {
      .right_utility {
        a {
          color: #2A9AFB;
        }
      }
    }
  }
  .price {
    font-size: 11px;
  }
  .total {
    font-size: 12px;
  }
}
@primary-color: #1DA57A;