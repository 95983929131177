#request_info {
  width: 774px !important;
  @media only screen and (min-width: 414px) and (max-width: 720px) {
    width: 90vw;
  }
  height: 193px;
  margin-top: 52px;
  margin-left: 15px;
}

#text_item {
  margin-top: 52px;
  margin-left: 12px;
  align-self: start;
}

#title_request {
  font-size: 14px;
  font-family: 'DINPro' sans-serif;
  color: #000000;
  width: 384px;
  margin-top: 21px;
  margin-left: 14px;
}

.green span {
  color: #FFFFFF;
  font-weight: bold;
  font-family: 'DINPro', sans-serif;
}

.title_request {
  text-align: left;
}