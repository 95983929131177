#finish_process_btn {
  width: 240px;
  height: 50px;
  border-radius: 30px;
  border: none;
  background-color: #4EA752;
  color: white;
  font-weight: bold;
  margin-top: 36px;
  text-align: center;
}